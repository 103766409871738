import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd';
import Persona from "./Persona";
import moment from "moment";
import {
  CITY_COORDS,
  DELIVERY_ORDER_CURRENCIES,
  DELIVERY_ORDER_TYPE, LINEMAN_APP_IDS,
  UBER_APP_IDS
} from "./consts";

class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appSearchQuery: '',
      customCity: false,
    };
  }
  render() {
    const { form, order, merchants, apps, personas } = this.props
    const { getFieldDecorator, setFields, getFieldValue } = form
    const filteredApps = this.state.appSearchQuery.length
      ? apps.filter(
        (x) =>
          x.package_name.includes(this.state.appSearchQuery.toLowerCase()) ||
          (x.display_name || '').toLowerCase().includes(this.state.appSearchQuery.toLowerCase()) ||
          x.id === parseInt(this.state.appSearchQuery)
      ).slice(0, 100)
      : []
    return (
      <Form>
        <Row gutter={[8, 8, 8]}>
          <Col span={8}>
            <Form.Item label="App">
              {getFieldDecorator('app_id', {
                rules: [{ required: true }],
                initialValue: order && order.app_id,
              })(<Select
                showSearch
                filterOption={false}
                onSearch={(value) => this.setState({ appSearchQuery: value })}
                onChange={x => {
                  let checkoutDefaultOpts = null
                  if (UBER_APP_IDS.includes(x)) {
                    checkoutDefaultOpts = JSON.stringify({ priority: null, standard: null, saver: null })
                  } else if (LINEMAN_APP_IDS.includes(x)) {
                    checkoutDefaultOpts = JSON.stringify({ direct_to_you: null, normal: null, low_cost: null })
                  }
                  if (checkoutDefaultOpts) {
                    if (!getFieldValue("checkout_fees_options")) {
                      setFields({ checkout_fees_options: { value: checkoutDefaultOpts } });
                    }
                    if (!getFieldValue("checkout_etas_options")) {
                      setFields({ checkout_etas_options: { value: checkoutDefaultOpts } })
                    }
                  }
                }}
              >
                {[...filteredApps, ...(order?.app_id ? apps.filter(x => x.id === order.app_id, 1) : [])].map((app) => (
                  <Select.Option value={app.id} key={app.id}>
                    {`#${app.id} ${app.display_name} [${app.platform}] (${app.package_name})`}
                  </Select.Option>
                ))}
              </Select>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Merchant">
              {getFieldDecorator('merchant_id', {
                rules: [{ required: true }],
                initialValue: order && order.merchant_id,
              })(<Select
                placeholder="Merchant"
                showSearch
                filterOption={(input, option) => option.props.children.join(' ').toLowerCase().includes(input.toLowerCase())}
              >
                {merchants.map((merchant) => (
                  <Select.Option value={merchant.id} key={merchant.id}>#{merchant.id} {merchant.name}</Select.Option>
                ))}
              </Select>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Distance(km)">
              {getFieldDecorator('distance_km', {
                rules: [{ required: true }],
                initialValue: order && order.distance_km,
              })(<InputNumber placeholder="Distance(km)" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[6, 6, 6, 6]}>
          <Col span={6}>
            <Form.Item label="Time Of Order">
              {getFieldDecorator('time_of_order', {
                rules: [{ required: true }],
                initialValue: order?.time_of_order ? moment.utc(order.time_of_order) : null,
                normalize: value => value && value.utc(),
              })(<DatePicker
                showTime
                format="YYYY-MM-DDTHH:mm"
                onChange={(value) => {
                  setFields(Object.fromEntries([
                   'preparing_order_timestamp',
                   'courier_ontheway_timestamp',
                   'order_arrived_timestamp',
                  ].filter(x => !getFieldValue(x)).map(x => [x, { value }])))
                }}
              />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Cart Size">
              {getFieldDecorator('cart_size', {
                rules: [{ required: true }],
                initialValue: order ? order.cart_size : "",
              })(<Input placeholder="Cart Size" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Cart Size Curr">
              {getFieldDecorator('cart_size_curr', {
                rules: [{ required: true }],
                initialValue: order && order.cart_size_curr,
              })(<Select>
                {DELIVERY_ORDER_CURRENCIES.map((curr) => (
                  <Select.Option value={curr} key={curr}>{curr}</Select.Option>
                ))}
              </Select>)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Order Type">
              {getFieldDecorator('order_type', {
                rules: [{ required: false }],
                initialValue: order ? order.order_type : null,
              })(<Select>
                <Select.Option value={null} key={null}>&nbsp;</Select.Option>
                {DELIVERY_ORDER_TYPE.map((type) => (
                  <Select.Option value={type} key={type}>{type}</Select.Option>
                ))}
              </Select>)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[6, 6, 6, 6]}>
          <Col span={6}>
            <Form.Item label="Persona">
              {getFieldDecorator('persona_id', {
                rules: [{ required: true }],
                initialValue: order ? order.persona_id : null,
              })(<Select
                placeholder="Persona"
                showSearch
                filterOption={(input, option) =>
                  [
                    `#${option.props.children.props.persona.id}`,
                    option.props.children.props.persona.location,
                    option.props.children.props.persona.title,
                  ]
                    .join(' ')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {personas.map((persona) => (
                  <Select.Option value={persona.id} key={persona.id}>
                    <Persona persona={persona} />
                  </Select.Option>
                ))}
              </Select>)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Persona City">
              {getFieldDecorator('persona_city', {
                rules: [{ required: true }],
                initialValue: order ? order.persona_city : '',
              })(this.state.customCity
              ? <Input placeholder="Persona City" style={{ width: '75%' }} />
              : <Select
                style={{ width: '75%' }}
                placeholder="Persona City"
                showSearch
                filterOption={(input, option) => {
                  return option.key.toLowerCase().includes(input.toLowerCase())
                }}
                onChange={x => {
                  const coords = CITY_COORDS[x]
                  if (coords) {
                    setFields({ persona_lat: { value: coords[0] }, persona_long: { value: coords[1] } });
                  }
                }}
              >
                {Object.keys(CITY_COORDS).map((city) => (
                  <Select.Option value={city} key={city}>{city}</Select.Option>
                ))}
              </Select>)}
              <Checkbox style={{ paddingLeft: '1rem' }} checked={this.state.customCity} onChange={x => this.setState({ customCity: !this.state.customCity })}>Custom</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Persona Latitude">
              {getFieldDecorator('persona_lat', {
                initialValue: order ? order.persona_lat : null,
                normalize: value => value || null,
              })(<InputNumber placeholder="Persona Latitude" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Persona Longitude">
              {getFieldDecorator('persona_long', {
                initialValue: order && order.persona_long,
                normalize: value => value || null,
              })(<InputNumber placeholder="Persona Longitude" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[6, 6, 6, 6]}>
          <Col span={6}>
            <Form.Item label="Store Card Fee Text">
              {getFieldDecorator('store_card_fee_text', {
                initialValue: order ? order.store_card_fee_text : '',
              })(<Input placeholder="Store Card Fee Text" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Store Header Fee Text">
              {getFieldDecorator('store_header_fee_text', {
                initialValue: order ? order.store_header_fee_text : '',
              })(<Input placeholder="Store Header Fee Text" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Store Card Fee">
              {getFieldDecorator('store_card_fee', {
                rules: [{ required: true }],
                initialValue: order ? order.store_card_fee : 0,
              })(<InputNumber placeholder="Store Card Fee" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Store Header Fee">
              {getFieldDecorator('store_header_fee', {
                rules: [{ required: true }],
                initialValue: order ? order.store_header_fee : 0,
              })(<InputNumber placeholder="Store Header Fee" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8, 8]}>
          <Col span={8}>
            <Form.Item label="Checkout Fees Options">
              {getFieldDecorator('checkout_fees_options', {
                rules: [{ required: false }],
                initialValue: order ? JSON.stringify(order.checkout_fees_options) : null,
                normalize: value => value || null,
              })(<Input placeholder="Checkout Fees Options" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Checkout Final Fees">
              {getFieldDecorator('checkout_final_fees', {
                rules: [{ required: true }],
                initialValue: order ? order.checkout_final_fees : 0,
              })(<InputNumber placeholder="Checkout Final Fees" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Checkout Plan">
              {getFieldDecorator('checkout_plan', {
                initialValue: order ? order.checkout_plan : '',
              })(<Input placeholder="Checkout Plan" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8, 8]}>
          <Col span={8}>
            <Form.Item label="Checkout Etas Options">
              {getFieldDecorator('checkout_etas_options', {
                rules: [{ required: false }],
                initialValue: order ? JSON.stringify(order.checkout_etas_options) : null,
                normalize: value => value || null,
              })(<Input placeholder="Checkout Etas Options" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Store Card ETA">
              {getFieldDecorator('store_card_eta', {
                initialValue: order ? order.store_card_eta : '',
              })(<Input placeholder="Store Card ETA" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Store Header ETA">
              {getFieldDecorator('store_header_eta', {
                initialValue: order ? order.store_header_eta : '',
              })(<Input placeholder="Store Header ETA" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[6, 6, 6, 6]}>
          <Col span={6}>
            <Form.Item label="Preparing Order Timestamp">
              {getFieldDecorator('preparing_order_timestamp', {
                rules: [{ required: true }],
                initialValue: order?.preparing_order_timestamp ? moment.utc(order.preparing_order_timestamp) : null,
                normalize: value => value && value.utc(),
              })(<DatePicker showTime format="YYYY-MM-DDTHH:mm" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Preparing Order ETA">
              {getFieldDecorator('preparing_order_eta', {
                rules: [{ required: true }],
                initialValue: order ? order.preparing_order_eta : '',
              })(<Input placeholder="Preparing Order ETA" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Courier Assigned Timestamp">
              {getFieldDecorator('courier_assigned_timestamp', {
                initialValue: order?.courier_assigned_timestamp ? moment.utc(order.courier_assigned_timestamp) : null,
                normalize: value => value && value.utc(),
              })(<DatePicker showTime format="YYYY-MM-DDTHH:mm" />)}
              <Button
                type="link"
                title="Copy from 'Time Of Order'"
                onClick={() => setFields({ courier_assigned_timestamp: { value: getFieldValue('time_of_order') } })}
              >copy from 'Time Of Order'</Button>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Courier Assigned ETA">
              {getFieldDecorator('courier_assigned_eta', {
                initialValue: order ? order.courier_assigned_eta : '',
              })(<Input placeholder="Courier Assigned ETA" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[6, 6, 6, 6]}>
          <Col span={6}>
            <Form.Item label="Courier Ontheway Timestamp">
              {getFieldDecorator('courier_ontheway_timestamp', {
                rules: [{ required: true }],
                initialValue: order?.courier_ontheway_timestamp ? moment.utc(order.courier_ontheway_timestamp) : null,
                normalize: value => value && value.utc(),
              })(<DatePicker showTime format="YYYY-MM-DDTHH:mm" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Courier Ontheway ETA">
              {getFieldDecorator('courier_ontheway_eta', {
                rules: [{ required: true }],
                initialValue: order ? order.courier_ontheway_eta : '',
              })(<Input placeholder="Courier Ontheway ETA" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Order Arrived Timestamp">
              {getFieldDecorator('order_arrived_timestamp', {
                rules: [{ required: true }],
                initialValue: order?.order_arrived_timestamp ? moment.utc(order.order_arrived_timestamp) : null,
                normalize: value => value && value.utc(),
              })(<DatePicker showTime format="YYYY-MM-DDTHH:mm" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Order Arrived">
              {getFieldDecorator('order_arrived', {
                initialValue: order ? order.order_arrived : '',
              })(<Input placeholder="Order Arrived" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[6, 12]}>
          <Col span={6}>
            <Form.Item label="Order Arrived On Last ETA">
              {getFieldDecorator('order_arrived_on_last_eta', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: order ? order.order_arrived_on_last_eta : false,
              })(<Checkbox placeholder="Order Arrived On Last ETA">Order Arrived On Last ETA</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Video Link">
              {getFieldDecorator('video_link', {
                initialValue: order ? order.video_link : '',
              })(<Input placeholder="Video Link" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(OrderForm);

export default ({ order, addSubmit, updSubmit, handleHide, merchants, apps, personas }) => {
  let formRef = null;

  return (
    <Modal
      title={order ? `Change order #${order.id}` : 'Add new order'}
      centered
      visible
      width="80%"
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            const updValues = {
              ...values,
              checkout_fees_options: (values.checkout_fees_options ? JSON.parse(values.checkout_fees_options) : values.checkout_fees_options),
              checkout_etas_options: (values.checkout_etas_options ? JSON.parse(values.checkout_etas_options) : values.checkout_etas_options),
            }
            if (errors) {
              return;
            }
            if (order) {
              updSubmit(order.id, updValues);
            } else {
              addSubmit(updValues);
            }
            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        order={order}
        merchants={merchants}
        apps={apps}
        personas={personas}
      />
    </Modal>
  );
};
