/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { Table, Input, Select, Button, Popconfirm, Icon, Checkbox, Alert, DatePicker, Modal, Typography } from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import {
  getAllUserGroups,
  getAllUserGroupPerms,
  setPermUserGroups,
  setDomainUserGroups,
  setOrchestratorUserGroups,
  setAppUserGroups,
  addUserGroups,
  deleteUserGroups,
  addProductToUserGroup,
  delProductFromUserGroup,
  setUserGroups,
} from '../../redux/actions/userGroupsPage';
import { getAllApps } from '../../redux/actions/usersPage';
import { getOrchestratorsList } from '../../redux/actions/performancePage';
import { ALL_PLATFORMS } from '../../constants/platforms';
import AddOrgModal from './addOrg/AddOrgModal';
import EditOrgAppsModal from './editApps/EditOrgAppsModal';
import EditUsersAppsModal from './editApps/EditUsersAppsModal';
import { getAllProducts } from '../../redux/actions/products';
import ProductAutocomplete from './products/ProductAutocomplete';
import { createInsightHighlight, sendHighlightEmail } from '../../api/insightsAPI';
import { getOrgHighlights, getSlackMessage, getSlackRecipients, getSlackSenders } from '../../api/organizationsAPI';
import SlackHighlightNotification from './HighlightSlack/SlackHighlightNotification';
import slack_ico from '../../assets/icons/1280px-Slack_Technologies_Logo.png';
import { getColumnSearchProps } from '../../utils/helpers';
import { getAllPersonas } from '../../redux/actions/personas';

const { Paragraph } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

export const getOrgHighlightsLink = (link_code) => `https://app.watchful.ai/link-bi-weekly/${link_code}`;

function UserGroups({
  getAllUserGroups,
  setPermUserGroups,
  setDomainUserGroups,
  getAllApps,
  allUserGroups,
  deleteUserGroups,
  getOrchestratorsList,
  setOrchestratorUserGroups,
  setAppUserGroups,
  addUserGroups,
  getAllProducts,
  products,
  addProductToUserGroup,
  delProductFromUserGroup,
  allApps,
  orchestrators,
  isLoading,
  error,
  getAllUserGroupPerms,
  allPermissions,
  setUserGroups,
  getAllPersonas,
  personas,
}) {
  const [addUserGroupModalVisible, addUserGroupModalVisibleChange] = useState(false);
  const [editOrgAppsModalVisible, editOrgAppsModalVisibleChange] = useState(false);
  const [editUsersAppsModalVisible, editUsersAppsModalVisibleChange] = useState(false);

  const [editOrgAppsID, editOrgAppsIDChange] = useState(null);

  const [editMode, editModeChange] = useState(false);
  const [userGroups, userGroupsChange] = useState([]);
  const [userGroupsDict, userGroupsDictChange] = useState(new Map());
  const [addPerm, addPermChange] = useState({});
  const [addDomain, addDomainChange] = useState({});
  const [addLoc, addLocChange] = useState({});
  const [usersLimit, usersLimitChange] = useState({});
  const [generatedHighlights, setGeneratedHighlights] = useState({});
  const [orgHighlightsModalCurId, setOrgHighlightsModalCurId] = useState(null);
  const [currHighlight, currHighlightChange] = useState(null);
  const [tableSearchQuery, tableSearchQueryChange] = useState('');

  const allAppsDict = useMemo(() => {
    const dict = new Map();
    allApps.forEach((element) => dict.set(element.id, element));
    return dict;
  }, [allApps]);

  const orgHighlights = useQuery(
    ['curOrgHighlights', orgHighlightsModalCurId],
    () => orgHighlightsModalCurId && getOrgHighlights(orgHighlightsModalCurId)
  );
  const highlightsMutation = useMutation(createInsightHighlight, {
    onSuccess: (data, variables) => {
      setGeneratedHighlights({
        ...generatedHighlights,
        [variables.organization_id]: {
          link: getOrgHighlightsLink(data.data.link_code),
        },
      });
    },
    onError: (error, variables) => {
      setGeneratedHighlights({
        ...generatedHighlights,
        [variables.organization_id]: { error: JSON.parse(error.message).msg },
      });
    },
  });
  const highlightEmailMutation = useMutation(sendHighlightEmail, {
    onSuccess: (data, variables) => orgHighlights.refetch(),
  });
  const recipients = useQuery('recipients', getSlackRecipients);
  const senders = useQuery('senders', getSlackSenders);
  const prevMessages = useQuery('prevMessages', getSlackMessage);

  const handleGenerateHighlightsLink = async (orgId) => {
    const date_from = moment().startOf('isoWeek').subtract(2, 'weeks').format('YYYY-MM-DD');
    const date_to = moment().startOf('isoWeek').format('YYYY-MM-DD');
    await highlightsMutation.mutateAsync({ organization_id: orgId, date_from, date_to });
  };
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    tableSearchQueryChange(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    tableSearchQueryChange('');
  };
  const COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', handleSearch, handleReset),
      render: (name, record) => (
        <div>
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[tableSearchQuery]}
            autoEscape
            textToHighlight={name ? name.toString() : ''}
          />
          {editMode && (
            <div>
              <Button
                type="default"
                style={{ marginTop: '1rem' }}
                onClick={() => {
                  editOrgAppsIDChange(record.id);
                  editOrgAppsModalVisibleChange(true);
                }}
              >
                Edit org apps
              </Button>
              <br />
              <Button
                type="default"
                style={{ marginTop: '1rem' }}
                onClick={() => {
                  editOrgAppsIDChange(record.id);
                  editUsersAppsModalVisibleChange(true);
                }}
              >
                Missing apps
              </Button>
              <br />
            </div>
          )}
          <div>
            <Button
              type="primary"
              icon="menu"
              style={{ marginTop: '1rem' }}
              onClick={() => {
                setOrgHighlightsModalCurId(record.id);
                editOrgAppsIDChange(record.id);
              }}
              loading={highlightsMutation.isLoading}
            >
              Show all highlights
            </Button>
            {generatedHighlights[record.id] && generatedHighlights[record.id].link ? (
              ''
            ) : (
              <Button
                type="primary"
                icon="file-add"
                style={{ marginTop: '1rem' }}
                onClick={() => handleGenerateHighlightsLink(record.id)}
                loading={highlightsMutation.isLoading}
              >
                Generate highlights link
              </Button>
            )}
            {generatedHighlights[record.id] && (
              <div>
                {generatedHighlights[record.id].link && (
                  <a href={generatedHighlights[record.id].link} rel="noopener noreferrer" target="_blank">
                    {generatedHighlights[record.id].link}
                  </a>
                )}
                {generatedHighlights[record.id].error && (
                  <div style={{ color: 'red' }}>{generatedHighlights[record.id].error}</div>
                )}
              </div>
            )}
            <br />
          </div>
          {editMode && (
            <div style={{ display: 'flex', margin: '1rem 0' }}>
              <label htmlFor="" style={{ marginRight: '2rem' }}>
                Min Highlights Num To Send:{' '}
              </label>
              <Paragraph strong editable={{ onChange: (x) => handleUpdateMinHighlightsNumToSend(record.id, x) }}>
                {record.min_highlights_num_to_send}
              </Paragraph>
            </div>
          )}
          {editMode && (
            <div className="slack-wrapper">
              <div>
                <Checkbox
                  checked={record.slack_webhook_enable}
                  onChange={(e) => {
                    setUserGroups({
                      id: record.id,
                      slack_webhook_enable: !record.slack_webhook_enable,
                    });
                  }}
                >
                  <img src={slack_ico} width="50px" height="15px" alt="slack" />
                </Checkbox>
              </div>
              <div className="slack-url">
                <Input
                  type="text"
                  placeholder="Organizational slack webhook"
                  onChange={(e) => {
                    setUserGroups({
                      id: record.id,
                      slack_webhook: e.currentTarget.value,
                    });
                  }}
                  value={record.slack_webhook}
                  disabled={!record.slack_webhook_enable}
                />
              </div>
            </div>
          )}
          {editMode && (
            <div>
              <Popconfirm
                title="Are you sure?"
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={() => {
                  handleDeleteUserGroup(record.id);
                }}
              >
                <Button type="danger" icon="delete" style={{ marginTop: '1rem' }}>
                  Delete Org
                </Button>
              </Popconfirm>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Domains',
      dataIndex: 'domains',
      key: 'domains',
      render: (domains, record) => (
        <div className="domains-wrap">
          {domains &&
            domains.map((d) => (
              <div key={d.id}>
                {d.name}
                {editMode && (
                  <Button
                    type="primary"
                    shape="round"
                    icon="close"
                    size="small"
                    onClick={() => removeDomainReq(record, d.name)}
                  />
                )}
              </div>
            ))}
          {editMode && (
            <div style={{ marginBottom: '1rem' }}>
              <Input
                placeholder="Add Domain"
                style={{ width: '10rem' }}
                value={addDomain[record.id]}
                onChange={(e) => handleChangeAddDomain(e.currentTarget.value, record.id)}
              />
              <Button type="primary" onClick={() => addDomainReq(record)}>
                Add
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Locations',
      dataIndex: 'locations',
      key: 'locations',
      render: (locations, record) => (
        <div className="locations-wrap">
          {locations &&
            locations.map((loc) => (
              <div>
                {loc.hostname} ({loc.location})
                {editMode && (
                  <Button
                    type="primary"
                    shape="round"
                    icon="close"
                    size="small"
                    onClick={() => removeHostnamesReq(record, loc.id)}
                  />
                )}
              </div>
            ))}
          {editMode && (
            <div style={{ marginBottom: '1rem' }}>
              <Select
                style={{ width: '10rem' }}
                onChange={(val) => handleChangeAddLoc(val, record.id)}
                value={addLoc[record.id]}
              >
                {orchestrators &&
                  orchestrators
                    .filter((orc) => !locations.find((loc) => loc.id === orc.id))
                    .map((orc) => (
                      <Option value={orc.id}>
                        {orc.hostname} ({orc.location})
                      </Option>
                    ))}
              </Select>
              <Button type="primary" onClick={() => addHostnamesReq(record)}>
                Add
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (permissions, record) => (
        <div className="perm-wrap">
          {permissions &&
            permissions.map((perm) => (
              <div key={perm.id}>
                {perm.name}
                {editMode && (
                  <Button
                    type="primary"
                    shape="round"
                    icon="close"
                    size="small"
                    onClick={() => removePermission(record, perm.name)}
                  />
                )}
              </div>
            ))}
          {editMode && (
            <div style={{ marginBottom: '1rem' }}>
              <Select
                style={{ width: '15rem' }}
                onChange={(val) => handleChangeAddPerm(val, record.id)}
                value={addPerm[record.id]}
              >
                {allPermissions
                  .filter((x) => !permissions.map((p) => p.name).includes(x))
                  .map((x) => (
                    <Option title={x} value={x}>
                      {x}
                    </Option>
                  ))}
              </Select>
              <Button type="primary" onClick={() => addPermission(record)}>
                Add
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Products',
      dataIndex: 'products',
      key: 'products',
      render: (_products, record) => (
        <div>
          {_products &&
            _products.map((product) => (
              <div key={product.id}>
                #{product.id} {product.name}
                {editMode && (
                  <Button
                    type="primary"
                    shape="round"
                    icon="close"
                    size="small"
                    onClick={() => delProductFromUserGroup(record.id, product.id)}
                  />
                )}
              </div>
            ))}
          {editMode && (
            <div style={{ marginBottom: '1rem' }}>
              <ProductAutocomplete
                products={products.filter((p) => !record.products.map((x) => x.id).includes(p.id))}
                onSelect={(selProduct) => addProductToUserGroup(record.id, selProduct)}
                style={{ width: '10rem' }}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      width: '40%',
      render: (users, record) => (
        <div className="org-users">
          <div className="org-users-limit">Users limit - {record.user_num_limit}</div>
          {editMode && (
            <div className="org-users-limit-edit">
              <Input
                type="text"
                value={usersLimit[record.id]}
                onChange={(e) => handleChangeUsersLimit(e.target.value, record.id)}
              />
              <Button
                type="primary"
                onClick={() => {
                  handleUpdateUsersLimit(record.id);
                }}
              >
                Update
              </Button>
            </div>
          )}
          <hr />
          {users.some((x) => x.is_org_admin) || (
            <Alert message="Warning" description="No admin assigned to this organization." type="warning" showIcon />
          )}
          <div className="org-users-list">
            {users &&
              [...users]
                .sort((a, b) => b.is_org_admin - a.is_org_admin)
                .map((user) => (
                  <div>
                    {user.is_org_admin && <Icon type="star" title="Org admin" />} {user.first_name} {user.last_name} -{' '}
                    {user.email}
                  </div>
                ))}
          </div>
        </div>
      ),
    },
    {
      title: 'Expiration date',
      dataIndex: 'trial_expiration_date',
      key: 'trial_expiration_date',
      render: (expirationDate, record) => (
        <div className="org-users">
          <div className="org-expiration-date">
            {editMode ? (
              <DatePicker
                defaultValue={moment(expirationDate)}
                format={dateFormat}
                placeholder="Expiration date"
                onChange={(date) => {
                  setUserGroups({
                    id: record.id,
                    trial_expiration_date: moment(date).format(dateFormat),
                  });
                }}
              />
            ) : (
              expirationDate && moment(expirationDate).format(dateFormat)
            )}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getOrchestratorsList();
    getAllUserGroups();
    getAllUserGroupPerms();
    getAllApps(ALL_PLATFORMS);
    getAllProducts();
    getAllPersonas();
  }, []);

  useEffect(() => {
    const mapUserGroups = allUserGroups.map((ug, i) => ({
      key: i,
      name: ug.name,
      id: ug.id,
      domains: ug.domains,
      locations: ug.orchestrator_permissions,
      users: ug.users,
      permissions: ug.permissions,
      apps: ug.apps,
      user_num_limit: ug.user_num_limit,
      min_highlights_num_to_send: ug.min_highlights_num_to_send,
      trial_expiration_date: ug.trial_expiration_date,
      slack_webhook_enable: ug.slack_webhook_enable,
      slack_webhook: ug.slack_webhook,
      products: ug.products,
    }));
    const mapUserGroupsDict = new Map();
    mapUserGroups.forEach((element) => {
      mapUserGroupsDict.set(element.id, element);
    });

    userGroupsChange(mapUserGroups);
    userGroupsDictChange(mapUserGroupsDict);
    addPermChange({});
    addDomainChange({});
    addLocChange({});
    usersLimitChange(
      allUserGroups.reduce((acc, el) => {
        acc[el.id] = el.user_num_limit;
        return acc;
      }, {})
    );
  }, [allUserGroups]);

  const removePermission = (record, perm_name) => {
    setPermUserGroups({
      org_id: record.id,
      data: {
        add: [],
        remove: [perm_name],
      },
    });
    console.log(`remove org - ${record.id} : perm_id - ${perm_name}`);
  };

  const addPermission = (record) => {
    const perm_name = addPerm[record.id];
    setPermUserGroups({
      org_id: record.id,
      data: {
        add: [perm_name],
        remove: [],
      },
    });
    console.log(`add org - ${record.id} : perm_id - ${perm_name}`);
  };

  const removeDomainReq = (record, domain_name) => {
    setDomainUserGroups({
      org_id: record.id,
      data: {
        add: [],
        remove: [domain_name],
      },
    });
    console.log(`remove domain - ${record.id} : perm_id - ${domain_name}`);
  };

  const addDomainReq = (record) => {
    const domain_name = addDomain[record.id];

    setDomainUserGroups({
      org_id: record.id,
      data: {
        add: [domain_name],
        remove: [],
      },
    });

    console.log(`add domain org - ${record.id} : perm_id - ${domain_name}`);
  };

  const removeHostnamesReq = (record, host_name) => {
    setOrchestratorUserGroups({
      org_id: record.id,
      data: {
        add: [],
        remove: [host_name],
      },
    });
    console.log(`remove loc - ${record.id} : perm_id - ${host_name}`);
  };

  const addHostnamesReq = (record) => {
    const host_name = addLoc[record.id];
    setOrchestratorUserGroups({
      org_id: record.id,
      data: {
        add: [host_name],
        remove: [],
      },
    });
    console.log(`add hostname org - ${record.id} : perm_id - ${host_name}`);
  };

  const removeAppsReq = (record_id, app_id) => {
    setAppUserGroups({
      org_id: record_id,
      data: {
        set: [],
        remove: [app_id],
      },
    });
    console.log(`remove app - ${record_id} : perm_id - ${app_id}`);
  };

  const addAppsReq = (record_id, apps) => {
    const appsForSave = apps.map((app) => ({
      app_id: app.app_id || app.id,
      benchmarking_preference_order_index: app.benchmarking_preference_order_index || 0,
      has_experiments: app.has_experiments || false,
      has_performance: app.has_performance || false,
      intelligence_preference_order_index: app.intelligence_preference_order_index || 0,
      id: app.app_id ? app.id : null,
      organization_id: app.organization_id || editOrgAppsID,
      should_send_intel_email_for_own_app: app.should_send_intel_email_for_own_app || false,
      personas: app.personas || [],
    }));

    setAppUserGroups({
      org_id: record_id,
      data: {
        set: appsForSave,
        remove: [],
      },
    });
    console.log(`add apps org - ${record_id}`);
  };

  const handleUpdateUsersLimit = (org_id) => {
    setUserGroups({
      id: org_id,
      user_num_limit: usersLimit[org_id],
    });
  };

  const handleUpdateMinHighlightsNumToSend = (orgId, num) => {
    setUserGroups({
      id: orgId,
      min_highlights_num_to_send: num,
    });
  };

  const handleChangeAddPerm = (val, org_id) => {
    const addPermCopy = { ...addPerm };
    addPermCopy[org_id] = val;
    addPermChange(addPermCopy);
  };

  const handleChangeAddDomain = (val, org_id) => {
    const addDomainCopy = { ...addDomain };
    addDomainCopy[org_id] = val;
    addDomainChange(addDomainCopy);
  };

  const handleChangeAddLoc = (val, org_id) => {
    const addLocCopy = { ...addLoc };
    addLocCopy[org_id] = val;
    addLocChange(addLocCopy);
  };

  const handleChangeUsersLimit = (val, org_id) => {
    const usersLimitCopy = { ...usersLimit };
    usersLimitCopy[org_id] = val;
    usersLimitChange(usersLimitCopy);
  };

  const handleAddUserGroup = (name) => {
    addUserGroups({ name });
  };

  const handleDeleteUserGroup = (org_id) => {
    deleteUserGroups(org_id);
  };

  if (allUserGroups.length === 0 || orchestrators.length === 0 || allApps.length === 0) {
    return (
      <h2 style={{ textAlign: 'center' }}>
        {' '}
        Loading <Icon type="loading" />
      </h2>
    );
  }

  return (
    <div className="user-groups">
      <div style={{ margin: '1rem 0' }}>
        <Button onClick={() => addUserGroupModalVisibleChange(true)} type="primary" style={{ margin: 16 }}>
          Add Organization
        </Button>
        <Checkbox onChange={(e) => editModeChange(e.target.checked)} checked={editMode}>
          Edit Mode
        </Checkbox>
      </div>
      {error && (
        <Alert
          message={error.message}
          description={error.response.body && error.response.body.error}
          type="error"
          showIcon
        />
      )}
      <Table
        dataSource={userGroups}
        loading={isLoading}
        columns={COLUMNS}
        className="user-groups-table"
        pagination={false}
      />
      ;
      {editOrgAppsID && (
        <EditOrgAppsModal
          modalVisible={editOrgAppsModalVisible}
          setIsVisible={(visible) => editOrgAppsModalVisibleChange(visible)}
          orgMode
          orgID={editOrgAppsID}
          allApps={allApps}
          personas={personas}
          sourceOrgApps={userGroupsDict.get(editOrgAppsID).apps.map((app, index) => {
            const extApp = allAppsDict.get(app.app_id);
            return {
              key: index,
              ...extApp,
              ...app,
            };
          })}
          deleteAppForOrg={removeAppsReq}
          saveAppsForOrg={addAppsReq}
        />
      )}
      {editOrgAppsID && (
        <EditUsersAppsModal
          modalVisible={editUsersAppsModalVisible}
          setIsVisible={(visible) => editUsersAppsModalVisibleChange(visible)}
          allApps={allApps}
          orgsApps={userGroups
            .find((ug) => ug.id === editOrgAppsID)
            .apps.map((app) => {
              const extApp = allApps.find((a) => a.id === app.app_id);
              return {
                ...extApp,
                ...app,
              };
            })}
          orgsUsers={userGroups.find((ug) => ug.id === editOrgAppsID).users}
          orgID={editOrgAppsID}
          addAppsForUser={() => {}}
          removeAppForUser={() => {}}
        />
      )}
      {orgHighlightsModalCurId && (
        <Modal
          title={`Organization #${orgHighlightsModalCurId} highlights`}
          centered
          width="60%"
          visible
          cancelButtonProps={{ style: { display: 'none' } }}
          onOk={() => setOrgHighlightsModalCurId(null)}
          onCancel={() => setOrgHighlightsModalCurId(null)}
        >
          <Table
            dataSource={orgHighlights.isFetched ? orgHighlights.data.data.sort((a, b) => b.id - a.id) : []}
            loading={orgHighlights.isLoading}
            columns={[
              { title: 'From', dataIndex: 'date_from' },
              { title: 'To', dataIndex: 'date_to' },
              {
                title: 'Link',
                dataIndex: 'link_code',
                render: (link_code) => (
                  <a rel="noopener noreferrer" target="_blank" href={getOrgHighlightsLink(link_code)}>
                    {getOrgHighlightsLink(link_code)}
                  </a>
                ),
              },
              {
                title: 'Emails Sent At',
                dataIndex: 'emails_sent_at',
                render: (value) =>
                  value ? <div title={value}>{moment(value).format(moment.HTML5_FMT.DATE)}</div> : '',
              },
              {
                title: 'Share',
                key: 'share',
                render: (value, record) => (
                  <div>
                    {highlightEmailMutation.isSuccess && highlightEmailMutation.variables.highlightId === record.id && (
                      <div>
                        Successfully sent to{' '}
                        {highlightEmailMutation.data && highlightEmailMutation.data.data.emails.length} users
                      </div>
                    )}
                    {highlightEmailMutation.isError && highlightEmailMutation.variables.highlightId === record.id && (
                      <div>Error occurred: see browser console log for details</div>
                    )}
                    {((highlightEmailMutation.isSuccess || highlightEmailMutation.isError) &&
                      highlightEmailMutation.variables.highlightId === record.id) || (
                      <Popconfirm
                        title="Do you really want to send email to all org user?"
                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                        onConfirm={() => highlightEmailMutation.mutateAsync({ highlightId: record.id })}
                      >
                        <Button
                          loading={
                            highlightEmailMutation.isLoading &&
                            highlightEmailMutation.variables.highlightId === record.id
                          }
                          icon="mail"
                          type="small"
                          title="send email to org users"
                        >
                          Send Emails
                        </Button>
                      </Popconfirm>
                    )}

                    <Button
                      type="small"
                      title="slack message"
                      onClick={() => currHighlightChange(record.id)}
                      style={{ marginLeft: '10px' }}
                      disabled={
                        prevMessages &&
                        prevMessages.data.some((message) =>
                          message.text.includes(getOrgHighlightsLink(record.link_code))
                        )
                      }
                    >
                      <img src={slack_ico} width="50px" height="15px" />
                    </Button>
                  </div>
                ),
              },
            ]}
            pagination={false}
          />
        </Modal>
      )}
      {currHighlight && (
        <SlackHighlightNotification
          highlight={orgHighlights.data.data.find((highlight) => highlight.id === currHighlight)}
          visible={!!currHighlight}
          visibleChange={currHighlightChange}
          recipients={recipients.data.filter(
            (recipient) => recipient.org === userGroups.find((gr) => gr.id === editOrgAppsID).name
          )}
          senders={senders.data.filter(
            (sender) => sender.org === userGroups.find((gr) => gr.id === editOrgAppsID).name
          )}
        />
      )}
      <AddOrgModal
        addOrg={handleAddUserGroup}
        modalVisible={addUserGroupModalVisible}
        setIsVisible={(visible) => addUserGroupModalVisibleChange(visible)}
      />
    </div>
  );
}

const mapStateToProps = ({ userGroupsPage, usersPage, emailJSONPage, productsData, personasPage }) => ({
  allUserGroups: userGroupsPage.allUserGroups,
  allPermissions: userGroupsPage.allPermissions,
  configuration: emailJSONPage.configuration,
  allApps: usersPage.allApps,
  orchestrators: userGroupsPage.orchestrators,
  products: productsData.productsData,
  isLoading: userGroupsPage.isLoading,
  error: userGroupsPage.error,
  personas: personasPage.personas,
});

export default connect(mapStateToProps, {
  getOrchestratorsList,
  getAllUserGroups,
  getAllUserGroupPerms,
  setPermUserGroups,
  setAppUserGroups,
  setDomainUserGroups,
  setOrchestratorUserGroups,
  getAllApps,
  deleteUserGroups,
  addUserGroups,
  getAllProducts,
  addProductToUserGroup,
  delProductFromUserGroup,
  setUserGroups,
  getAllPersonas,
})(UserGroups);
