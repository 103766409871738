import React, { useEffect, useState } from 'react';
import ReactJSONView from "react-json-view";
import { Radio, Button, Input, message, Popconfirm } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { getSettings, setSetting } from '../../../api/dbSettingsAPI';
import {
  extractInsightMetaDataAsync,
  extractInsightMetaDataSync
} from "../../../api/insightsMetaData";
import moment from "moment";

const SETTING_KEY_PUSH_CONTEXT = 'insight_promo_push_meta_data_extraction_context'
const SETTING_KEY_PUSH_PROMPT = 'insight_promo_push_meta_data_extraction_prompt'
const SETTING_KEY_PUSH_MODEL = 'insight_promo_push_meta_data_extraction_model'

const SETTING_KEY_EMAIL_CONTEXT = 'insight_promo_email_meta_data_extraction_context'
const SETTING_KEY_EMAIL_PROMPT = 'insight_promo_email_meta_data_extraction_prompt'
const SETTING_KEY_EMAIL_MODEL = 'insight_promo_email_meta_data_extraction_model'

const MODELS = ['gpt-4o-mini', 'gpt-4o']

const INSIGHT_TYPE_CONF = {
  push_notification_campaign: {
    title: "Push Notification",
    setting_key_context: SETTING_KEY_PUSH_CONTEXT,
    setting_key_prompt: SETTING_KEY_PUSH_PROMPT,
    setting_key_model: SETTING_KEY_PUSH_MODEL,
  },
  email_campaign: {
    title: "Email",
    setting_key_context: SETTING_KEY_EMAIL_CONTEXT,
    setting_key_prompt: SETTING_KEY_EMAIL_PROMPT,
    setting_key_model: SETTING_KEY_EMAIL_MODEL,
  },
}


export default ({ insightType }) => {
  const conf = INSIGHT_TYPE_CONF[insightType]
  const [context, setContext] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [model, setModel] = useState(null);
  const [insightIdsText, setInsightIdsText] = useState("");
  const [metaDataExtractionResult, setMetaDataExtractionResult] = useState(null);

  const onError = (data) => message.error(data.message, 3)

  const settings = useQuery('settings', getSettings);
  const setSettingMutation = useMutation(setSetting, {
    onSuccess: (data) => {
      settings.refetch()
      if (data.data.name === conf.setting_key_context) {
        setContext(data.data.value)
        message.success("Context updated", 3)
      } else if (data.data.name === conf.setting_key_prompt) {
        setPrompt(data.data.value)
        message.success("Prompt updated", 3)
      } else if (data.data.name === conf.setting_key_model) {
        setModel(data.data.value)
        message.success("Model updated", 3)
      }
    },
    onError: onError,
  });
  const extractInsightMetaDataSyncMutation = useMutation(extractInsightMetaDataSync, {
    onSuccess: (data) => {
      setMetaDataExtractionResult(data);
      message.success("Meta Data extracted", 3)
    },
    onError: onError,
  });
  const extractInsightMetaDataAsyncMutation = useMutation(extractInsightMetaDataAsync, {
    onSuccess: (data) => {
      setMetaDataExtractionResult(data);
      message.success("Tasks enqueued", 3)
    },
    onError: onError,
  });

  const dbModel = settings.data && settings.data.data.find((x) => x.name === conf.setting_key_model).value
  const dbContext = settings.data && settings.data.data.find((x) => x.name === conf.setting_key_context).value
  const dbPrompt = settings.data && settings.data.data.find((x) => x.name === conf.setting_key_prompt).value

  useEffect(() => {
    setModel(dbModel);
    setContext(dbContext);
    setPrompt(dbPrompt);
  }, [dbModel, dbContext, dbPrompt])

  const isPromptLoading = settings.isLoading || setSettingMutation.isLoading
  const isMetaDataExtractionLoading = extractInsightMetaDataAsyncMutation.isLoading || extractInsightMetaDataSyncMutation.isLoading
  const isContextChanged = dbContext !== context
  const isPromptChanged = dbPrompt !== prompt

  return (
    <div style={{ margin: "1rem" }}>
      <h2>Promo <b>{conf.title}</b> Meta Data Extraction</h2>
      <p>Chat GPT Prompt Configuration</p>
      <div style={{ display: "flex", justifyContent: "space-between", gap: "2rem" }}>
        <div style={{ flex: 1 }}>
          <h3>Model:</h3>
          <Radio.Group
            options={MODELS.map(x => ({ label: x, value: x }))}
            onChange={(e) => setSettingMutation.mutateAsync({ name: conf.setting_key_model, value: e.target.value })}
            optionType="button"
            buttonStyle="solid"
            value={model}
          />
          <h3>Context{isContextChanged && '*'}:</h3>
          <p>This field allows you to provide general information or instructions for the model. It is sent with the role "system" and is used to configure the model's behavior. For example, you can specify the response style, language, level of formality, or any other parameters the model should consider when processing your request. Think of it as guidelines or boundaries for the conversation to help the model better understand your expectations.</p>
          <Input.TextArea
            value={context}
            disabled={isPromptLoading}
            rows={5}
            onChange={e => setContext(e.target.value)}
          />
          <Button
            style={{ marginTop: "0.5rem" }}
            onClick={() => setSettingMutation.mutateAsync({ name: conf.setting_key_context, value: context })}
            disabled={isPromptLoading}
            loading={isPromptLoading}
            title={isContextChanged && 'Context changed'}
          >Update Context{isContextChanged && '*'}</Button>
        </div>
        <div style={{ flex: 1 }}>
          <h3>Prompt{isContextChanged && '*'}:</h3>
          <p>Allowed macros: <code>{'{{app_name}}'}</code>, <code>{'{{insight_title}}'}</code>, <code>{'{{insight_description}}'}</code></p>
          <Input.TextArea
            value={prompt}
            disabled={isPromptLoading}
            rows={10}
            title={isPromptChanged && 'Prompt changed'}
            onChange={e => setPrompt(e.target.value)}
          />
          <Button
            style={{ marginTop: "0.5rem" }}
            onClick={() => setSettingMutation.mutateAsync({ name: conf.setting_key_prompt, value: prompt })}
            disabled={isPromptLoading}
            loading={isPromptLoading}
            title={isPromptChanged && 'Prompt changed'}
          >Update Prompt{isPromptChanged && '*'}</Button>
        </div>
      </div>
      <div>
        <h2>Run Insight Meta Data Extraction</h2>
        <Input.TextArea
          style={{ width: '50%' }}
          placeholder="coma separated insight IDs"
          value={insightIdsText}
          disabled={isMetaDataExtractionLoading}
          rows={3}
          onChange={e => setInsightIdsText(e.target.value)}
        />
        <div style={{ display: "flex", gap: "0.5rem", margin: "0.5rem 0" }}>
          <Button
            type="danger"
            disabled={isMetaDataExtractionLoading || !insightIdsText.trim().length || insightIdsText.split(",").map(x => x.trim()).filter(x => x).length > 20}
            loading={isMetaDataExtractionLoading}
            title="Allowed not more than 20 insights; You can see results in the UI"
            onClick={() => extractInsightMetaDataSyncMutation.mutateAsync({ insight_ids: insightIdsText.split(",").map(x => x.trim()).filter(x => x) })}
          >Run now!</Button>
          <Button
            type="primary"
            disabled={isMetaDataExtractionLoading || !insightIdsText.trim().length}
            loading={isMetaDataExtractionLoading}
            title="Create async task. You can use it in case when you don't need to see result in the UI and you have to run on more than 20 insights"
            onClick={() => extractInsightMetaDataAsyncMutation.mutateAsync({ insight_ids: insightIdsText.split(",").map(x => x.trim()).filter(x => x) })}
          >Run batch</Button>
          <Popconfirm
            disabled={isMetaDataExtractionLoading}
            title="Are you sure to run meta data extraction on insights for the last 7 days? It might be very expensive"
            onConfirm={() => extractInsightMetaDataAsyncMutation.mutateAsync({
              created_since: moment().subtract(7, "days").format("YYYY-MM-DD"),
              insight_type: insightType,
            })}
          >
            <Button
              type="primary"
              loading={isMetaDataExtractionLoading}
              disabled={isMetaDataExtractionLoading}
              title="Use it when you're sure that prompt is correct and we can re-run meta data extraction on and big number of insights for the last 7 days"
            >Run for the last 7 days</Button>
          </Popconfirm>
          <Popconfirm
            disabled={isMetaDataExtractionLoading}
            title="Are you sure to run meta data extraction on insights for the last 30 days? It might be very expensive"
            onConfirm={() => extractInsightMetaDataAsyncMutation.mutateAsync({
              created_since: moment().subtract(30, "days").format("YYYY-MM-DD"),
              insight_type: insightType,
            })}
          >
            <Button
              type="primary"
              loading={isMetaDataExtractionLoading}
              disabled={isMetaDataExtractionLoading}
              title="Use it when you're sure that prompt is correct and we can re-run meta data extraction on and big number of insights for the last month"
            >Run for the last 30 days</Button>
          </Popconfirm>
          <Popconfirm
            disabled={isMetaDataExtractionLoading}
            title="Are you sure to run meta data extraction on insights for the last 3 month? It might be very expensive"
            onConfirm={() => extractInsightMetaDataAsyncMutation.mutateAsync({
              created_since: moment().subtract(3, "months").format("YYYY-MM-DD"),
              insight_type: insightType,
            })}
          >
            <Button
              type="primary"
              loading={isMetaDataExtractionLoading}
              disabled={isMetaDataExtractionLoading}
              title="Use it when you're sure that prompt is correct and we can re-run meta data extraction on and big number of insights for the last 3 months"
            >Run for the last 3 months</Button>
          </Popconfirm>
        </div>
        {isMetaDataExtractionLoading && <div>Extracting meta data...</div>}
        {metaDataExtractionResult && (
          <div>
            <h3>Response:</h3>
            <ReactJSONView
              style={{ maxHeight: "400px", width: "50%", overflow: "auto" }}
              src={metaDataExtractionResult}
              collapsed={false}
            />
          </div>
        )}
      </div>
    </div>
  );
}
