"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdatePersonasManagementDataMutation = exports.useDeviceData = void 0;
var react_query_1 = require("react-query");
var persona_management_api_1 = require("api/persona-management-api");
var antd_1 = require("antd");
var useDeviceData = function () {
    var personasData = (0, react_query_1.useQuery)(['PersonaManagementData'], persona_management_api_1.getPersonasManagementData).data;
    return personasData;
};
exports.useDeviceData = useDeviceData;
var useUpdatePersonasManagementDataMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(persona_management_api_1.updatePersonasManagementData, {
        onSuccess: function () {
            antd_1.message.success('Device data updated');
            queryClient.invalidateQueries(['PersonaManagementData']);
        },
    });
};
exports.useUpdatePersonasManagementDataMutation = useUpdatePersonasManagementDataMutation;
