"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditCreateModal = void 0;
var antd_1 = require("antd");
var react_1 = __importStar(require("react"));
var Modal = antd_1.Modal;
var defaultInitialValues = {
    name: '',
};
var EditCreateModal = function (_a) {
    var mode = _a.mode, visible = _a.visible, onClose = _a.onClose, onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var _b = (0, react_1.useState)(defaultInitialValues), values = _b[0], setValues = _b[1];
    var handleFieldsChange = function (field) { return function (e) {
        var _a;
        var value = typeof e === 'object' ? e.target.value : e;
        setValues(__assign(__assign({}, values), (_a = {}, _a[field] = value, _a)));
    }; };
    (0, react_1.useEffect)(function () {
        setValues(initialValues !== null && initialValues !== void 0 ? initialValues : defaultInitialValues);
    }, [initialValues]);
    var handleSubmit = function () {
        onSubmit(values);
    };
    var handleClose = function () {
        setValues(defaultInitialValues);
        onClose();
    };
    return (react_1.default.createElement(Modal, { title: mode === 'create' ? 'Create Marketing Event' : 'Update Marketing Event', centered: true, visible: visible, onCancel: handleClose, footer: null },
        react_1.default.createElement(antd_1.Form, { layout: "vertical" },
            react_1.default.createElement(antd_1.Form.Item, { label: "Name" },
                react_1.default.createElement(antd_1.Input, { type: "text", onChange: handleFieldsChange('name'), value: values.name, required: true, placeholder: "Event Name..." })),
            react_1.default.createElement(antd_1.Form.Item, null,
                react_1.default.createElement(antd_1.Button, { type: "primary", htmlType: "submit", onClick: handleSubmit }, mode === 'create' ? 'Create' : 'Update')))));
};
exports.EditCreateModal = EditCreateModal;
