/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Upload,
  Button,
  Icon,
  Popconfirm,
} from 'antd';
import { RELEASE_STATES } from '../../../constants/releases';
import moment from 'moment';

class ReleaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoFillFromApk: true,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const { isLoading, presignedUrl, insights, release, isAdmin } = this.props;

    const hasPackageFile = !!(
      getFieldValue('package_file') &&
      getFieldValue('package_file').fileList &&
      getFieldValue('package_file').fileList.length
    );
    const isApk =
      hasPackageFile &&
      ['apk', 'xapk', 'apks'].some((x) => hasPackageFile && getFieldValue('package_file').fileList[0].name.endsWith(x));

    const stateOptions = RELEASE_STATES.map((x) => (
      <Select.Option key={x} value={x}>
        {x}
      </Select.Option>
    ));

    const releasesOptions = this.props.releases.map((x) => (
      <Select.Option key={x.id} value={x.id}>
        {x.release_name}({x.version_code})
      </Select.Option>
    ));

    if (isLoading) {
      return (
        <h2 style={{ textAlign: 'center' }}>
          {' '}
          Loading <Icon type="loading" />
        </h2>
      );
    }

    return (
      <Form onSubmit={this.handleSubmit} className="release-form">
        {presignedUrl && (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item label="Package File">
                {getFieldDecorator(
                  'package_file',
                  {}
                )(
                  <Upload
                    name="file"
                    multiple={false}
                    listType="text"
                    accept="*"
                    action={presignedUrl.s3_data.url}
                    data={presignedUrl.s3_data.fields}
                    disabled={!isAdmin}
                  >
                    <Button icon="upload" disabled={!!hasPackageFile || !isAdmin}>
                      Click to upload
                    </Button>
                  </Upload>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}

        {isApk && (
          <Checkbox
            checked={this.state.autoFillFromApk}
            onChange={(e) => this.setState({ autoFillFromApk: e.target.checked })}
            disabled={!isAdmin}
          >
            Fill form automatically from Apk
          </Checkbox>
        )}

        {(!isApk || !this.state.autoFillFromApk) && (
          <div>
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <Form.Item label="Version Code">
                  {getFieldDecorator('version_code', {
                    rules: [{ required: true }],
                    initialValue: this.props.release && this.props.release.version_code,
                  })(<InputNumber precision={0} placeholder="Version Code" disabled={!isAdmin} />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Release Name">
                  {getFieldDecorator('release_name', {
                    rules: [{ required: true }],
                    initialValue: this.props.release && this.props.release.release_name,
                  })(<Input placeholder="Release Name" disabled={!isAdmin} />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Binary Link">
                  {getFieldDecorator('binary_link', {
                    rules: [
                      {
                        type: 'url',
                        message: 'Please enter a valid url',
                      },
                    ],
                    initialValue: release && release.binary_link,
                  })(<Input placeholder="binary_link" disabled={!isAdmin} />)}
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Release Date">
              {getFieldDecorator('release_date', {
                rules: [{ required: true }],
                initialValue: release && moment(release.release_date),
              })(<DatePicker placeholder="release_date" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Is Visible">
              {getFieldDecorator('is_visible', {
                rules: [],
                valuePropName: 'checked',
                initialValue: release ? release.is_visible : false,
              })(<Checkbox disabled={!isAdmin}>Is Visible</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="State">
              {getFieldDecorator('state', {
                rules: [{ required: true }],
                initialValue: release ? release.state : 'new',
              })(
                <Select placeholder="State" disabled={!isAdmin}>
                  {stateOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Parent Release">
              {getFieldDecorator('parent_release_id', {
                initialValue: release && release.parent_release_id,
              })(
                <Select placeholder="parent_release_id" disabled={!isAdmin}>
                  <Select.Option key="" value={null}>
                    &nbsp;
                  </Select.Option>
                  {releasesOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Is Comparison Completed">
              {getFieldDecorator('is_comparison_completed', {
                initialValue: release && release.is_comparison_completed,
              })(
                <Select placeholder="Is Comparison Completed" disabled={!isAdmin}>
                  <Select.Option key="yes" value>
                    Yes
                  </Select.Option>
                  <Select.Option key="no" value={false}>
                    No
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Use Version Date">
              {getFieldDecorator('_use_version_date', {
                initialValue: release ? release._use_version_date !== false : true,
              })(
                <Select placeholder="Use Version Date" disabled={!isAdmin}>
                  <Select.Option key="yes" value>
                    Yes
                  </Select.Option>
                  <Select.Option key="no" value={false}>
                    No
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item label="Summary">
              {getFieldDecorator('summary', {
                rules: [],
                initialValue: release ? release.summary : '',
              })(<Input.TextArea disabled={!isAdmin} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="APK source" title="For example: Google Store, PureAPK">
              {getFieldDecorator('apk_source', {
                rules: [],
                initialValue: release ? release.apk_source : '',
              })(<Input disabled={!isAdmin} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create({ name: 'release-form' })(ReleaseForm);

export default function ReleaseModal({
  handleHide,
  handleSubmit,
  appId,
  release,
  releases,
  isLoading,
  presignedUrl,
  insights,
  isAdmin,
}) {
  let formRef = null;
  const [isAnalysisConfirmModalVisible, setIsAnalysisConfirmModalVisible] = useState(false);

  const handleOk = (startAnalysis = false) => {
    if (formRef) {
      formRef.props.form.validateFields((errors, values) => {
        if (errors) {
          return;
        }
        let submitData = {
          ...values,
          app_id: appId,
          release_date: values.release_date.format('YYYY-MM-DD'),
          analysis_start_date: startAnalysis ? moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) : undefined,
        };
        if (values.package_file && values.package_file.fileList.length) {
          const { name } = values.package_file.fileList[0].originFileObj;
          submitData = {
            ...submitData,
            package_file: {
              name,
              url: presignedUrl.url,
              s3_filename: presignedUrl.filename,
            },
          };
        }
        if (!isAdmin) {
          // non-admin user can update only release_date
          submitData = { release_date: submitData.release_date };
        }
        if (release) {
          handleSubmit(release.id, submitData);
        } else {
          handleSubmit(submitData);
        }
        handleHide();
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        title={release ? `Edit release #${release.release_name}` : `Add New Release for app #${appId}`}
        centered
        width="60%"
        visible
        onOk={() => {
          if (
            release &&
            !release.analysis_start_date &&
            ['in_review', 'analysis_pending', 'analysis_in_progress', 'published'].includes(
              formRef.props.form.getFieldValue('state')
            )
          ) {
            setIsAnalysisConfirmModalVisible(true);
          } else {
            handleOk(false);
          }
        }}
        onCancel={() => {
          handleHide();
        }}
      >
        <WrappedForm
          wrappedComponentRef={(form) => {
            formRef = form;
          }}
          release={release}
          releases={releases}
          isLoading={isLoading}
          presignedUrl={presignedUrl}
          insights={insights}
          isAdmin={isAdmin}
        />
        <Popconfirm
          title="Start Analysis process?"
          visible={isAnalysisConfirmModalVisible}
          placement="bottomRight"
          onConfirm={() => handleOk(true)}
          onCancel={() => handleOk(false)}
          onVisibleChange={() => setIsAnalysisConfirmModalVisible(false)}
        />
      </Modal>
    </React.Fragment>
  );
}
