"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteTimelineEvent = exports.DELETE_TIMELINE_EVENT = exports.useUpdateTimelineEvent = exports.UPDATE_TIMELINE_EVENT = exports.useCreateTimelineEvent = exports.CREATE_TIMELINE_EVENT = exports.useGetTimelineEvents = exports.GET_TIMELINE_EVENTS = void 0;
var react_query_1 = require("react-query");
var antd_1 = require("antd");
var marketing_event_api_1 = require("api/marketing-event-api");
exports.GET_TIMELINE_EVENTS = 'GET_TIMELINE_EVENTS';
var useGetTimelineEvents = function (_a) {
    var app_id = _a.app_id, persona_id = _a.persona_id;
    return (0, react_query_1.useQuery)([exports.GET_TIMELINE_EVENTS, app_id, persona_id], function () { return (0, marketing_event_api_1.getMarketingTimelineEvents)({ app_id: app_id, persona_id: persona_id }); }, {
        enabled: !!app_id && !!persona_id,
    });
};
exports.useGetTimelineEvents = useGetTimelineEvents;
exports.CREATE_TIMELINE_EVENT = ['CREATE_TIMELINE_EVENT'];
var useCreateTimelineEvent = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.CREATE_TIMELINE_EVENT, marketing_event_api_1.createMarketingTimelineEvent, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_TIMELINE_EVENTS);
            antd_1.message.success('Marketing Timeline Event created successfully');
        },
        onError: function () {
            antd_1.message.error('Failed to create Marketing Timeline Event');
        },
    });
};
exports.useCreateTimelineEvent = useCreateTimelineEvent;
exports.UPDATE_TIMELINE_EVENT = ['UPDATE_TIMELINE_EVENT'];
var useUpdateTimelineEvent = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.UPDATE_TIMELINE_EVENT, function (_a) {
        var id = _a.id, data = _a.data;
        return (0, marketing_event_api_1.updateMarketingTimelineEvent)({ id: id, data: data });
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_TIMELINE_EVENTS);
            antd_1.message.success('Marketing Timeline Event updated successfully');
        },
        onError: function () {
            antd_1.message.error('Failed to update Marketing Timeline Event');
        },
    });
};
exports.useUpdateTimelineEvent = useUpdateTimelineEvent;
exports.DELETE_TIMELINE_EVENT = ['DELETE_TIMELINE_EVENT'];
var useDeleteTimelineEvent = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.DELETE_TIMELINE_EVENT, function (_a) {
        var id = _a.id;
        return (0, marketing_event_api_1.deleteMarketingTimelineEvent)(id);
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_TIMELINE_EVENTS);
            antd_1.message.success('Marketing Timeline Event deleted successfully');
        },
        onError: function () {
            antd_1.message.error('Failed to delete Marketing Timeline Event');
        },
    });
};
exports.useDeleteTimelineEvent = useDeleteTimelineEvent;
