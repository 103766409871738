import { Alert, Button, Modal, Popconfirm, Select, Table } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import { useMutation } from 'react-query';
import { updateInsights } from '../../../api/insightsAPI';
import { INSIGHT_CAMPAIGN_SUBTYPE } from '../../../constants/insightType';
import AppSelector from '../../Insight2/AppSelector';
import { PLATFORMS } from '../../../constants/platforms';
import CreatableSelect from 'react-select/creatable';

export const BulkOperationModal = ({ selectedInsights, onHide, refreshMainTable, setSelectedInsights, focusAreas }) => {
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [subType, setSubType] = useState(null);
  const [app, setApp] = useState(null);
  const [focusArea, setFocusArea] = useState(null);
  const updInsightsMutation = useMutation(updateInsights, {
    onSuccess: ({ data }, variables) => {
      setSelectedInsights(
        selectedInsights.map((x) => ({
          ...x,
          app: data.find((d) => d.id === x.id)?.app,
          focus_area: data.find((d) => d.id === x.id)?.focus_area,
          ...variables.find((v) => v.id === x.id),
        }))
      );
      refreshMainTable();
      setSuccessMessage(`Updated insights: ${data.map((x) => x.id).join(', ')}`);
    },
    onError: (error) => setError(error),
  });

  const isLoading = [updInsightsMutation.isLoading].some((x) => x);

  const updSelectedInsights = (data) => {
    setError(null);
    setSuccessMessage(null);
    updInsightsMutation.mutateAsync(selectedInsights.map((x) => ({ id: x.id, ...data })));
  };

  return (
    <Modal
      title="Bulk operation"
      centered
      visible
      onCancel={onHide}
      footer={[
        <Button type="default" onClick={() => onHide()}>
          Close
        </Button>,
      ]}
      closable
      width="80%"
      height="70%"
    >
      <h3>Selected Insights</h3>
      <div style={{ display: 'flex', paddingBottom: '0.5rem' }}>
        <Select value={subType} onChange={setSubType} style={{ width: '15rem', marginRight: '0.5rem' }}>
          <Select.Option value={null} key={null}>
            &nbsp;
          </Select.Option>
          {Object.entries(INSIGHT_CAMPAIGN_SUBTYPE).map(([key, value]) => (
            <Select.Option value={key} key={key}>
              {value}
            </Select.Option>
          ))}
        </Select>
        <Popconfirm
          title={`Are you sure to update ${selectedInsights.length} insights?`}
          onConfirm={() => updSelectedInsights({ content: { sub_type: subType } })}
        >
          <Button type="primary" loading={isLoading} style={{ marginRight: '0.5rem' }}>
            Update Subtype
          </Button>
        </Popconfirm>
        <div className="update-app-modal-container" style={{ padding: 0, marginRight: '0.5rem' }}>
          <AppSelector isMarketing allowedPlatforms={PLATFORMS.marketing} curApp={app} onAppSelected={setApp} />
        </div>
        <Popconfirm
          title={`Are you sure to update ${selectedInsights.length} insights?`}
          onConfirm={() => updSelectedInsights({ app_id: app.id })}
          disabled={!app}
        >
          <Button type="primary" loading={isLoading} disabled={!app} style={{ marginRight: '0.5rem' }}>
            Update app
          </Button>
        </Popconfirm>
        {focusAreas && (
          <React.Fragment>
            <CreatableSelect
              isClearable={false}
              placeholder="Focus area"
              onChange={({ value }) => setFocusArea(focusAreas.find((x) => x.id === value))}
              value={
                focusArea
                  ? {
                      label: focusArea.name,
                      value: focusArea.id,
                    }
                  : undefined
              }
              options={(focusAreas || []).map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
            <Popconfirm
              title={`Are you sure to update ${selectedInsights.length} insights?`}
              onConfirm={() => updSelectedInsights({ focus_area_id: focusArea.id })}
              disabled={!focusArea}
            >
              <Button type="primary" loading={isLoading} disabled={!focusArea} style={{ marginRight: '0.5rem' }}>
                Update focus area
              </Button>
            </Popconfirm>
          </React.Fragment>
        )}
        <Popconfirm
          title={`Are you sure to unpublish ${selectedInsights.length} insights?`}
          onConfirm={() => updSelectedInsights({ should_show: false })}
        >
          <Button type="primary" loading={isLoading} style={{ marginRight: '0.5rem' }}>
            Unpublish
          </Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to show ${selectedInsights.length} insights?`}
          onConfirm={() => updSelectedInsights({ should_show: true })}
        >
          <Button type="primary" loading={isLoading} style={{ marginRight: '0.5rem' }}>
            Mark as visible
          </Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to delete ${selectedInsights.length} insights?`}
          onConfirm={() => updSelectedInsights({ is_deleted: true })}
        >
          <Button type="danger" loading={isLoading} style={{ marginRight: '0.5rem' }}>
            Mark as deleted
          </Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to undelete ${selectedInsights.length} insights?`}
          onConfirm={() => updSelectedInsights({ is_deleted: false })}
        >
          <Button type="primary" loading={isLoading} style={{ marginRight: '0.5rem' }}>
            Restore insights
          </Button>
        </Popconfirm>
      </div>
      {error && <Alert message="API error" description={JSON.stringify(error)} type="error" showIcon />}
      {successMessage && <Alert message="Success" description={successMessage} type="success" showIcon />}
      <Table
        loading={isLoading}
        dataSource={selectedInsights}
        className="insights-list"
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            width: '5rem',
          },
          {
            title: 'Title',
            dataIndex: 'title',
            width: '20rem',
            render: (title, insight) => (
              <div style={{ textDecoration: insight.is_deleted ? 'line-through' : 'none' }}>{title}</div>
            ),
          },
          {
            title: 'Type',
            dataIndex: 'type',
            width: '10rem',
          },
          {
            title: 'Subtype',
            dataIndex: 'content',
            key: 'syb_type',
            width: '5rem',
            render: (content, _) => content && content.sub_type,
          },
          {
            title: 'Focus Area',
            dataIndex: 'focus_area',
            width: '5rem',
            render: (focus_area, _) => focus_area && focus_area.name,
          },
          {
            title: 'Created',
            dataIndex: 'created',
            width: '10rem',
            render: (created, insight) => <div>{moment(created).format('ll')}</div>,
          },
          {
            title: 'Visible',
            dataIndex: 'should_show',
            width: '4rem',
            render: (should_show, insight) => <div>{should_show ? '🗹' : '☐'}</div>,
          },
          {
            title: 'Deleted',
            dataIndex: 'is_deleted',
            width: '4rem',
            render: (is_deleted, insight) => <div>{is_deleted ? '🗹' : '☐'}</div>,
          },
          {
            title: 'App',
            dataIndex: 'app',
            width: '10rem',
            render: (app, insight) => (
              <div className="app-data">
                <img src={app.icon} alt={app.name} />#{app.id} {app.name}
              </div>
            ),
          },
          {
            title: 'Analyst',
            dataIndex: 'analyst',
            width: '10rem',
            render: (analyst, insight) => <div>{analyst ? analyst.name : 'Auto'}</div>,
          },
          {
            width: '10rem',
            render: (_, insight) => (
              <Button
                type="default"
                onClick={() => setSelectedInsights(selectedInsights.filter((x) => x.id !== insight.id))}
              >
                Remove from this list
              </Button>
            ),
          },
        ]}
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};
