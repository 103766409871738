import React from 'react';
import InsightFormTextArea from './insightFormTextarea';
import { useSuggestions } from '../../insight-field-suggestions/hooks/useSuggestions';
import { InsightFieldSuggestions } from '../../insight-field-suggestions';

export const HolidaySelect = ({ ins, handleInsChange }) => {
  const { suggestions, onApplySuggestion, onRejectSuggestion } = useSuggestions({
    insight_id: ins.id,
    type: 'season',
  });

  const handleApplySuggestion = async (suggestion) => {
    await onApplySuggestion({ suggestion_id: suggestion.id });

    handleInsChange({
      holiday: suggestion?.name || '',
    });
  };

  const handleRejectSuggestion = async (suggestion) => {
    await onRejectSuggestion({ suggestion_id: suggestion.id });
  };

  return (
    <>
      <InsightFormTextArea
        insight={ins}
        handleInsChange={handleInsChange}
        title="Holiday"
        field="holiday"
        textarea={false}
        required={false}
      />
      {suggestions && suggestions.length > 0 && (
        <InsightFieldSuggestions
          title="Suggestions (holiday)"
          suggestions={suggestions}
          onApproveSuggestion={handleApplySuggestion}
          onRejectSuggestion={handleRejectSuggestion}
        />
      )}
    </>
  );
};
