import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { addInsightLabel, delInsightLabel, getInsightLabels } from '../../../api/insightsAPI';
import { useSuggestions } from '../../insight-field-suggestions/hooks/useSuggestions';
import { InsightFieldSuggestions } from '../../insight-field-suggestions';

const { Option } = Select;

export default ({ insightId, suggestions, setLabels }) => {
  const insightLabelsQuery = useQuery(['insightLabels', insightId], () => getInsightLabels(insightId), {
    onSuccess: (data) => setInsightLabels(data.data),
  });
  const [insightLabels, setInsightLabels] = useState([]);
  useEffect(() => setLabels(insightLabels.map((x) => x.name)), [insightLabels]);
  const addInsightLabelMutation = useMutation(addInsightLabel, {
    onSuccess: (data) => {
      setInsightLabels([
        ...insightLabels,
        {
          id: data.data.label.id,
          name: data.data.label.name,
        },
      ]);
      message.destroy();
      message.success('Label was added');
    },
  });
  const delInsightLabelMutation = useMutation(delInsightLabel, {
    onSuccess: (data, variables) => {
      setInsightLabels(insightLabels.filter((x) => x.id !== variables.labelId));
      message.destroy();
      message.success('Label was deleted');
    },
  });
  const handleUpdLabels = (labels) => {
    insightLabels
      .filter((x) => !labels.includes(x.name))
      .forEach((x) =>
        delInsightLabelMutation.mutateAsync({
          insightId,
          labelId: x.id,
        })
      );
    labels
      .filter((y) => !insightLabels.map((x) => x.name).includes(y))
      .forEach((labelName) =>
        addInsightLabelMutation.mutateAsync({
          insightId,
          labelName,
        })
      );
  };
  const isLoading = [insightLabelsQuery, addInsightLabelMutation, delInsightLabelMutation].some((x) => x.isLoading);

  const {
    suggestions: labelsSuggestionsInsight,
    onApplySuggestion: onLabelSuggestionApprove,
    onRejectSuggestion: onLabelSuggestionReject,
  } = useSuggestions({
    insight_id: insightId,
    type: 'labels',
  });

  const labelSuggestionsWithName = labelsSuggestionsInsight
    ?.map((suggestion) => ({
      ...suggestion,
      labelNames: suggestions
        ?.filter(({ id }) => suggestion?.name.split(',').map(Number).includes(id))
        .map((x) => x.name),
    }))
    .map((suggestion) => ({ ...suggestion, name: suggestion.labelNames?.join(', ') }));

  const handleApplyLabelSuggestion = async (suggestion) => {
    await onLabelSuggestionApprove({ suggestion_id: suggestion.id });

    if (!suggestion.labelNames?.length) return;

    handleUpdLabels([...insightLabels.map((x) => x.name), ...suggestion.labelNames]);
  };

  const handleRejectLabelSuggestion = async (suggestion) => {
    await onLabelSuggestionReject({ suggestion_id: suggestion.id });
  };

  return (
    <>
      <Select
        mode="tags"
        placeholder="Labels"
        style={{ width: '100%' }}
        onChange={(value) => handleUpdLabels(value)}
        value={insightLabels.map((x) => x.name)}
        loading={isLoading}
        disabled={isLoading}
        getPopupContainer={(trNode) => trNode}
        className={!!insightLabels.length || 'status-error'}
      >
        {suggestions.map((x) => (
          <Option value={x.name} key={x.name}>
            {x.name}
          </Option>
        ))}
      </Select>
      {labelSuggestionsWithName && labelSuggestionsWithName.length > 0 && (
        <InsightFieldSuggestions
          title="Suggestions (labels)"
          suggestions={labelSuggestionsWithName}
          onApproveSuggestion={handleApplyLabelSuggestion}
          onRejectSuggestion={handleRejectLabelSuggestion}
        />
      )}
    </>
  );
};
