import { backendApiRequest } from './request';

export const getOrgPerms = async (appId) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/apps/${appId}/orgs`,
  });

export const updOrgPerm = async ({ appId, orgId, body }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/apps/${appId}/orgs/${orgId}`,
    body,
  });

export const delOrgPerm = async ({ appId, orgId }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/apps/${appId}/orgs/${orgId}`,
  });

export const updOrgPerms = async ({ body }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/apps/orgs`,
    body,
  });

export const getApps = async () =>
  backendApiRequest({
    method: 'GET',
    path: `/app/data`,
  });
