"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonaManagement = exports.PERSONA_MANAGEMENT_PATH = void 0;
var react_1 = __importStar(require("react"));
var DeviceTable_1 = require("./components/DeviceTable");
var useDeviceData_1 = require("./hooks/useDeviceData");
var antd_1 = require("antd");
require("./PersonaManagement.scss");
exports.PERSONA_MANAGEMENT_PATH = 'persona-management';
var PersonaManagement = function (_a) {
    var history = _a.history;
    var personasData = (0, useDeviceData_1.useDeviceData)();
    (0, react_1.useEffect)(function () {
        var pathname = history.location.pathname;
        if (!pathname.includes("/".concat(exports.PERSONA_MANAGEMENT_PATH))) {
            history.replace("/".concat(exports.PERSONA_MANAGEMENT_PATH));
        }
    }, []);
    return (react_1.default.createElement("div", { className: "dm-container" }, personasData ? react_1.default.createElement(DeviceTable_1.DeviceTable, { devices: (personasData === null || personasData === void 0 ? void 0 : personasData.devices) || [] }) : react_1.default.createElement(antd_1.Spin, { spinning: true, size: "large" })));
};
exports.PersonaManagement = PersonaManagement;
