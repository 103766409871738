import React from 'react';
import { Modal, Form, Input } from 'antd';


class MerchantForm extends React.Component {
  render() {
    const { form, merchant } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true }],
            initialValue: merchant && merchant.name,
          })(<Input placeholder="Name" />)}
        </Form.Item>
        <Form.Item label="City">
          {getFieldDecorator('city', {
            rules: [{ required: true }],
            initialValue: merchant && merchant.city,
          })(<Input placeholder="City" />)}
        </Form.Item>
        <Form.Item label="Address">
          {getFieldDecorator('address', {
            rules: [{ required: true }],
            initialValue: merchant && merchant.address,
          })(<Input placeholder="Address" />)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(MerchantForm);

export default ({ merchant, addSubmit, updSubmit, handleHide }) => {
  let formRef = null;

  return (
    <Modal
      title={merchant ? `Change merchant #${merchant.id} ${merchant.name}` : 'Add new merchant'}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (merchant) {
              updSubmit(merchant.id, values);
            } else {
              addSubmit(values);
            }
            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        merchant={merchant}
      />
    </Modal>
  );
};
