import { backendApiRequest } from './request';

export const getDeliveryMerchants = async () =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/delivery/merchants`,
  });

export const addDeliveryMerchant = async (body) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/delivery/merchants`,
    body,
  });

export const updDeliveryMerchant = async ({ merchantId, body }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/delivery/merchants/${merchantId}`,
    body,
  });

export const delDeliveryMerchant = async (merchantId) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/delivery/merchants/${merchantId}`,
  });

export const getDeliveryOrders = async () =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/delivery/orders`,
  });

export const addDeliveryOrder = async (body) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/delivery/orders`,
    body,
  });

export const updDeliveryOrder = async ({ orderId, body }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/delivery/orders/${orderId}`,
    body,
  });

export const delDeliveryOrder = async (orderId) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/delivery/orders/${orderId}`,
  });
