"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteMarketingEvent = exports.DELETE_MARKETING_EVENT = exports.useUpdateMarketingEvent = exports.UPDATE_MARKETING_EVENT = exports.useCreateMarketingEvent = exports.CREATE_MARKETING_EVENT = exports.useGetMarketingEvents = exports.GET_MARKETING_EVENTS = void 0;
var react_query_1 = require("react-query");
var antd_1 = require("antd");
var marketing_event_api_1 = require("api/marketing-event-api");
exports.GET_MARKETING_EVENTS = ['GET_MARKETING_EVENTS'];
var useGetMarketingEvents = function () {
    return (0, react_query_1.useQuery)(exports.GET_MARKETING_EVENTS, marketing_event_api_1.getMarketingEvents);
};
exports.useGetMarketingEvents = useGetMarketingEvents;
exports.CREATE_MARKETING_EVENT = ['CREATE_MARKETING_EVENT'];
var useCreateMarketingEvent = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.CREATE_MARKETING_EVENT, marketing_event_api_1.createMarketingEvent, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_MARKETING_EVENTS);
            antd_1.message.success('Marketing Event created successfully');
        },
        onError: function () {
            antd_1.message.error('Failed to create Marketing Event');
        },
    });
};
exports.useCreateMarketingEvent = useCreateMarketingEvent;
exports.UPDATE_MARKETING_EVENT = ['UPDATE_MARKETING_EVENT'];
var useUpdateMarketingEvent = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.UPDATE_MARKETING_EVENT, function (_a) {
        var id = _a.id, name = _a.name;
        return (0, marketing_event_api_1.updateMarketingEvent)({ id: id, data: { name: name } });
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_MARKETING_EVENTS);
            antd_1.message.success('Marketing Event updated successfully');
        },
        onError: function () {
            antd_1.message.error('Failed to update Marketing Event');
        },
    });
};
exports.useUpdateMarketingEvent = useUpdateMarketingEvent;
exports.DELETE_MARKETING_EVENT = ['DELETE_MARKETING_EVENT'];
var useDeleteMarketingEvent = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.DELETE_MARKETING_EVENT, function (_a) {
        var id = _a.id;
        return (0, marketing_event_api_1.deleteMarketingEvent)(id);
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_MARKETING_EVENTS);
            antd_1.message.success('Marketing Event deleted successfully');
        },
        onError: function () {
            antd_1.message.error('Failed to delete Marketing Event');
        },
    });
};
exports.useDeleteMarketingEvent = useDeleteMarketingEvent;
