import React, { useState, useEffect } from 'react';
import Merchants from "./Merchants";
import { useQuery } from "react-query";
import { getDeliveryMerchants } from "../../api/deliveryAPI";
import { Button } from "antd";
import Orders from "./Orders";

export default () => {
  const merchantsQuery = useQuery(['merchants'], getDeliveryMerchants);
  const [showMerchants, setShowMerchants] = useState(false);
  return (
    <React.Fragment>
      <h1>Delivery Orders</h1>
      <h2>
        Merchants ({merchantsQuery.data?.data?.length || 0})
        <Button
          title="Show / Hide Merchants"
          onClick={() => setShowMerchants(!showMerchants)}
        >
          {showMerchants ? '-' : '+'}
        </Button>
      </h2>
      {showMerchants && <Merchants merchantsQuery={merchantsQuery} />}
      <h2>Orders</h2>
      <Orders merchantsQuery={merchantsQuery} />
    </React.Fragment>
  );
}
