/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Table, Checkbox, Select, Button, Popconfirm, Icon, InputNumber, Tooltip } from 'antd';
import { APP_PERSONA_PERM_INSIGHT_TYPES } from '../../../constants/insightType';
import { debounce } from 'lodash';
import { getColumnSearchProps } from '../../../utils/helpers';
import Highlighter from 'react-highlight-words';

const { Option } = Select;

const Persona = ({ persona }) => (
  <React.Fragment>
    #{persona.id}
    <span style={{ margin: '0px 2px' }} className={`flag-icon flag-icon-${(persona.location || '').toLowerCase()}`} />
    {persona.title}
  </React.Fragment>
);

export default function EditOrgAppsModal({
  modalVisible,
  setIsVisible,
  allApps,
  saveAppsForOrg,
  deleteAppForOrg,
  sourceOrgApps,
  orgID,
  personas,
}) {
  const [appList, setAppList] = useState([]);
  const [selectedAppIDS, setSelectedAppIDS] = useState([]);
  const [dataSource, setDataSource] = useState(null);
  const [tableSearchQuery, setTableSearchQuery] = useState('');

  useEffect(() => {
    setDataSource(sourceOrgApps);
  }, [sourceOrgApps]);

  const handleTableSearchByName = (selectedKeys, confirm) => {
    confirm();
    setTableSearchQuery(selectedKeys[0]);
  };

  const handleTableSearchByNameReset = (clearFilters) => {
    clearFilters();
    setTableSearchQuery('');
  };

  const orgPersonas = personas.filter((x) => x.organizations.map((o) => o.id).includes(orgID));

  const columns = [
    {
      title: 'App Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', handleTableSearchByName, handleTableSearchByNameReset),
      render: (app_name, record) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[tableSearchQuery]}
          autoEscape
          textToHighlight={app_name ? app_name.toString() : ''}
        >
          <div style={{ display: 'flex' }}>
            <img style={{ width: '25px', marginRight: '2px' }} src={record.icon} alt="app icon" /> <div>{app_name}</div>
          </div>
        </Highlighter>
      ),
    },
    {
      title: 'App Package Name',
      dataIndex: 'package',
      key: 'package',
      ...getColumnSearchProps('package', handleTableSearchByName, handleTableSearchByNameReset),
      render: (package_name, record) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[tableSearchQuery]}
          autoEscape
          textToHighlight={package_name ? package_name.toString() : ''}
        >
          {package_name}
        </Highlighter>
      ),
    },
    {
      title: 'App Platform',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'App Licenses',
      dataIndex: 'licences',
      key: 'licencesData',
      render: (text, record) => (
        <Select
          value={
            record.has_performance && record.has_experiments
              ? 'both'
              : record.has_performance
                ? 'performance'
                : record.has_experiments
                  ? 'experiments'
                  : ''
          }
          style={{ minWidth: '16rem' }}
          onChange={(event) =>
            setDataSource(
              dataSource.map((app) => {
                const a = { ...app };
                if (app.id === record.id) {
                  a.has_performance = true;
                  a.has_experiments = true;
                  if (event === 'experiments') {
                    a.has_performance = false;
                  } else if (event === 'performance') {
                    a.has_experiments = false;
                  }
                }
                return a;
              })
            )
          }
        >
          <Select.Option value="experiments">
            {record.platform === 'Marketing' ? 'Marketing' : 'Experiments (Intelligence)'}
          </Select.Option>
          <Select.Option value="performance">Performance</Select.Option>
          <Select.Option value="both">Both</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Personas',
      dataIndex: 'personas',
      key: 'personas',
      render: (text, record) =>
        record.has_experiments && (
          <div>
            <Tooltip title="List of personas filtered by org permissions(page 'Personas', column 'Organizations')">
              <Select
                placeholder="Personas"
                onChange={(value) =>
                  setDataSource(
                    dataSource.map((app) =>
                      app.id === record.id
                        ? {
                            ...app,
                            personas: [
                              { id: value, insight_types: [...APP_PERSONA_PERM_INSIGHT_TYPES] },
                              ...app.personas,
                            ],
                          }
                        : app
                    )
                  )
                }
                showSearch
                value={null}
                filterOption={(input, option) =>
                  [
                    `#${option.props.children.props.persona.id}`,
                    option.props.children.props.persona.location,
                    option.props.children.props.persona.title,
                  ]
                    .join(' ')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {orgPersonas
                  .filter((x) => !record.personas.map((p) => p.id).includes(x.id))
                  .map((persona) => (
                    <Select.Option value={persona.id} key={persona.id}>
                      <Persona persona={persona} />
                    </Select.Option>
                  ))}
              </Select>
            </Tooltip>
            {record.personas.map((persona) => (
              <div>
                <Button
                  type="primary"
                  shape="round"
                  icon="close"
                  size="small"
                  onClick={() =>
                    setDataSource(
                      dataSource.map((app) =>
                        app.id === record.id
                          ? { ...app, personas: app.personas.filter((x) => x.id !== persona.id) }
                          : app
                      )
                    )
                  }
                />
                <Persona persona={personas.find((x) => x.id === persona.id)} />
                <Select
                  mode="multiple"
                  placeholder="Insight types"
                  onChange={(value) =>
                    setDataSource(
                      dataSource.map((app) =>
                        app.id === record.id
                          ? {
                              ...app,
                              personas: app.personas.map((x) =>
                                x.id === persona.id ? { ...x, insight_types: [...value] } : x
                              ),
                            }
                          : app
                      )
                    )
                  }
                  style={{ width: '25rem' }}
                  value={persona.insight_types}
                >
                  {APP_PERSONA_PERM_INSIGHT_TYPES.map((insight_type) => (
                    <Select.Option value={insight_type} key={insight_type}>
                      {insight_type}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ))}
          </div>
        ),
    },
    {
      title: 'Benchmarking Order',
      dataIndex: 'benchmarking_preference_order_index',
      key: 'benchmarking_preference_order_index',
      render: (text, record) => (
        <InputNumber
          value={record.benchmarking_preference_order_index}
          style={{ width: 'flex' }}
          min={0}
          max={99}
          maxLength={2}
          allowClear
          size="small"
          onChange={(value) =>
            (Number.isInteger(value) || !value) &&
            setDataSource(
              dataSource.map((app) => {
                const a = { ...app };
                if (app.id === record.id) {
                  a.benchmarking_preference_order_index = value;
                }
                return a;
              })
            )
          }
        />
      ),
    },
    {
      title: 'Send Intel Email For Own App',
      dataIndex: 'should_send_intel_email_for_own_app',
      key: 'should_send_intel_email_for_own_app',
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={(e) =>
            setDataSource(
              dataSource.map((x) =>
                x.id === record.id
                  ? {
                      ...x,
                      should_send_intel_email_for_own_app: e.target.checked,
                    }
                  : x
              )
            )
          }
        />
      ),
    },
    {
      title: 'Delete App',
      key: 'deleteApp',
      render: (text, record) => (
        <Popconfirm
          title="Are you sure?"
          icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
          onConfirm={() => {
            handleDeleteAppForUser(record.app_id);
          }}
        >
          <Button type="danger" icon="delete">
            Delete App
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const handleSearch = (value) => {
    if (value && value.length >= 3) {
      setAppList(
        allApps.filter(
          (app) =>
            app.package &&
            app.name &&
            app.id &&
            !dataSource.map((a) => a.app_id).includes(app.id) &&
            (app.package.toLowerCase().includes(value.toLowerCase()) ||
              app.name.toLowerCase().includes(value.toLowerCase()) ||
              String(app.id).toLowerCase().includes(value.toLowerCase()))
        )
      );
    } else {
      setAppList([]);
    }
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 300), [dataSource]);

  const handleChange = (value) => {
    setSelectedAppIDS(value);
  };

  const handleAddAppsForUser = () => {
    setDataSource([
      ...dataSource,
      ...allApps.filter((app) => selectedAppIDS.includes(String(app.id))).map((x) => ({ ...x, personas: [] })),
    ]);
    setSelectedAppIDS([]);
  };

  const handleDeleteAppForUser = (appID) => {
    deleteAppForOrg(orgID, appID);
  };

  const handleSaveAppsForUser = () => {
    saveAppsForOrg(orgID, dataSource);
    setIsVisible(false);
    setAppList([]);
    setSelectedAppIDS([]);
    setDataSource(null);
  };

  return (
    <Modal
      width="fit-content"
      title="Edit org apps"
      centered
      visible={modalVisible}
      onOk={() => {
        handleSaveAppsForUser();
      }}
      onCancel={() => {
        setIsVisible(false);
        setDataSource(null);
      }}
    >
      <div className="new-app-search">
        <Select
          mode="multiple"
          value={selectedAppIDS}
          onSearch={debouncedHandleSearch}
          onChange={handleChange}
          placeholder="Select apps"
          notFoundContent={null}
          filterOption={false}
        >
          {appList.map((app) => (
            <Option key={app.id}>{`${app.name} - ${app.platform} (${app.package})`}</Option>
          ))}
        </Select>
        <Button disabled={selectedAppIDS.length === 0} onClick={handleAddAppsForUser}>
          Add
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{ position: 'bottom' }}
        scroll={{ y: 800 }}
        virtual
        // loading={isFetchingData}
      />
    </Modal>
  );
}
