import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import PersonasTab from './personasTab/Tab';
import SettingsTab from './settingsTab/Tab';
import { PersonaMaintenance } from './persona-maintanace';
import { MarketingEvents } from './marketing-events';

export const GENDER = ['male', 'female', 'unspecified'];

const { TabPane } = Tabs;

const Personas = ({ user, curUserDetails }) => (
  <Tabs type="card">
    <TabPane tab="Personas" key="1">
      <PersonasTab isAdmin={user.isAdmin} />
    </TabPane>
    {user.isAdmin && (
      <TabPane tab="Settings" key="2">
        <SettingsTab />
      </TabPane>
    )}
    <TabPane tab="Persona Maintenance" key="5">
      <PersonaMaintenance />
    </TabPane>
    {curUserDetails?.is_org_admin && (
      <TabPane tab="Marketing Events" key="7">
        <MarketingEvents />
      </TabPane>
    )}
  </Tabs>
);

const mapStateToProps = ({ usersPage, loginData }) => ({
  user: usersPage.user,
  curUserDetails: loginData.curUserDetails,
});

export default connect(mapStateToProps, () => ({}))(Personas);
