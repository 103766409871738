import * as AT from '../actionTypes';

// GET Insights
export const getInsights = (data) => ({
  type: AT.GET_INSIGHTS_LIST,
  payload: data,
});

export const getInsightsAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/insights?${new URLSearchParams(data).toString()}`,
    method: 'GET',
    label: 'GET_INSIGHTS_LIST',
    onSuccess: (body, dispatch) => dispatch(getInsightsSuccess(body)),
    // onFailure: (error, dispatch) => dispatch(getInsightsFailed(error)),
    onFailure: (error, dispatch) => dispatch(getInsightsFailed(error.response.body)),
  },
});

export const getInsightsSuccess = (body) => ({
  type: AT.GET_INSIGHTS_LIST_SUCCESS,
  payload: body,
});

export const getInsightsFailed = (error) => ({
  type: AT.GET_INSIGHTS_LIST_FAILED,
  error,
});
