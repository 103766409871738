export const DELIVERY_ORDER_CURRENCIES = ["USD", "EUR", "GBP", "THB"];
export const DELIVERY_ORDER_TYPE = ["Groceries", "Food"];
export const CITY_COORDS = {
  'Chiang Mai City': [18.7949, 98.948],
  'Phuket City': [7.8964, 98.355],
  'Nakhon Ratchasima': [14.9936, 102.112],
  'UTP - Chon Buri (Ex-pattaya)': [13.2907, 100.926],
  'UTP - Pattaya': [12.9282, 100.893],
  'Khon Kaen City': [16.4328, 102.838],
  'Bangkok': [13.7247, 100.53],
}
export const UBER_APP_IDS = [1182, 3388, 3496, 3305, 3332, 3948, 4212, 640, 3327, 4193]
export const LINEMAN_APP_IDS = [4433, 4446]
