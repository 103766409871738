"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonaMaintenance = exports.PersonaMaintenanceBase = void 0;
var react_1 = __importStar(require("react"));
require("./styles.scss");
// @ts-ignore
var AppSelect_1 = require("../../userJourneys/components/AppSelect");
// @ts-ignore
var apps_1 = require("../../../redux/actions/apps");
var react_redux_1 = require("react-redux");
var persona_select_1 = require("./components/persona-select");
var events_table_1 = require("./components/events-table");
var add_event_modal_1 = require("./components/add-event-modal");
var antd_1 = require("antd");
var marketing_timeline_event_1 = require("hooks/marketing-timeline-event");
var react_query_1 = require("react-query");
// @ts-ignore
var insightsAPI_1 = require("api/insightsAPI");
var PersonaMaintenanceBase = function (_a) {
    var apps = _a.apps, loadApps = _a.loadApps, personas = _a.personas;
    var _b = (0, react_1.useState)(null), curApp = _b[0], setCurApp = _b[1];
    var _c = (0, react_1.useState)(null), persona = _c[0], setPersona = _c[1];
    var _d = (0, react_1.useState)(false), addEventOpen = _d[0], setAddEventOpen = _d[1];
    var personasData = (0, react_query_1.useQuery)(['personas', curApp === null || curApp === void 0 ? void 0 : curApp.id], function () { return (0, insightsAPI_1.getPersonasByApp)(curApp.id); }, {
        enabled: !!curApp,
    }).data;
    var deleteTimeline = (0, marketing_timeline_event_1.useDeleteTimelineEvent)();
    var data = (0, marketing_timeline_event_1.useGetTimelineEvents)({ app_id: curApp === null || curApp === void 0 ? void 0 : curApp.id, persona_id: persona === null || persona === void 0 ? void 0 : persona.id }).data;
    var timelineEventsData = (data === null || data === void 0 ? void 0 : data.data) || [];
    (0, react_1.useEffect)(function () {
        if (apps.length < 1)
            loadApps(['Marketing']);
    }, [apps]);
    var handleAppSelect = function (appId) {
        setCurApp(apps.find(function (app) { return app.id === appId; }));
        setPersona(null);
    };
    var handlePersonaSelect = function (personaId) {
        setPersona(personas.find(function (persona) { return persona.id === personaId; }));
    };
    var handleDeleteEvent = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteTimeline.mutateAsync({ id: id })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", { className: "pm-container" },
        react_1.default.createElement("div", { className: "pm-filter" },
            react_1.default.createElement("label", null, "App"),
            react_1.default.createElement(AppSelect_1.AppSelect, { curApp: curApp, apps: apps, onSelect: handleAppSelect })),
        (personasData === null || personasData === void 0 ? void 0 : personasData.data) && (react_1.default.createElement("div", { className: "pm-filter" },
            react_1.default.createElement("label", null, "Persona"),
            react_1.default.createElement(persona_select_1.PersonaSelect, { persona: persona, personas: personasData === null || personasData === void 0 ? void 0 : personasData.data, onSelect: handlePersonaSelect }))),
        curApp && persona && timelineEventsData && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", null,
                react_1.default.createElement(antd_1.Button, { type: "primary", onClick: function () {
                        setAddEventOpen(true);
                    } }, "Add")),
            react_1.default.createElement(events_table_1.EventsTable, { events: timelineEventsData, onDelete: handleDeleteEvent }))),
        curApp && persona && (react_1.default.createElement(add_event_modal_1.AddEventModal, { appId: curApp.id, personaId: persona.id, open: addEventOpen, onClose: function () {
                setAddEventOpen(false);
            } }))));
};
exports.PersonaMaintenanceBase = PersonaMaintenanceBase;
var mapStateToProps = function (state) { return ({
    apps: state.apps.all,
    personas: state.personasPage.personas,
}); };
var mapDispatchToProps = {
    loadApps: apps_1.loadApps,
};
exports.PersonaMaintenance = (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps)(exports.PersonaMaintenanceBase);
