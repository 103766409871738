import { backendApiRequest } from './request';


export const extractInsightMetaDataSync = async (body) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/meta-data/extraction/sync`,
    body,
  });

export const extractInsightMetaDataAsync = async (body) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/meta-data/extraction/async`,
    body,
  });
