import React from 'react';
import { Modal, Form, Input, Checkbox, Select, Col, Row, Button, Table, DatePicker } from 'antd';
import Text from 'antd/es/typography/Text';
import moment from 'moment-timezone';
import { authTypes, paymentMethods, tariffPlans } from '../perosnas_helper';
import { REGEX_ASCII, REGEX_EMAIL } from '../../../constants/validation';
import WatchfulThumbnail from '../../../assets/icons/default_thumbnail.png';

class PersonaProductForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productSearchQuery: '',
      selectedSettingId: null,
      payment_detail: null,
    };
  }
  componentDidMount() {
    this.setState({ payment_detail: this.props.personaProduct && this.props.personaProduct.payment_detail });
  }

  render() {
    const { form, personaProduct, products, settings, persona, addProductSetting, delProductSetting, apps } =
      this.props;

    const isSameSetting = (a, b) => a.value === b.value && a.type === b.type;
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter((leftValue) => !right.some((rightValue) => compareFunction(leftValue, rightValue)));

    const { getFieldDecorator } = form;
    const { productSearchQuery, selectedSettingId } = this.state;
    const isCurProduct = (product) => personaProduct && personaProduct.product_id === product.id;
    const filteredProducts = productSearchQuery.length
      ? products
          .filter((x) => x.name.toLowerCase().includes(productSearchQuery.toLowerCase()) || isCurProduct(x))
          .slice(0, 100)
      : products.filter(isCurProduct);
    const curProductId = form.getFieldValue('product_id');
    const curAppId = form.getFieldValue('marketing_app_id');
    const productApps = apps.filter((x) => x.platform === 'Marketing' && x.product_id === curProductId);
    const productItem = personaProduct ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={products.find(isCurProduct).thumbnail || WatchfulThumbnail}
          style={{ width: '25px', height: '25px' }}
          alt="product thumbnail"
        />
        <Text style={{ marginLeft: '5px' }}>{products.find(isCurProduct).name}</Text>
      </div>
    ) : (
      <Select
        showSearch
        filterOption={false}
        /* eslint-disable-next-line no-shadow */
        onSearch={(productSearchQuery) => this.setState({ productSearchQuery })}
        placeholder="Product"
        allowClear
        onChange={(value) => {
          if (curAppId && apps.find((x) => x.id === curAppId).product_id !== curProductId) {
            form.setFields({ marketing_app_id: undefined });
          }
        }}
      >
        {filteredProducts.map((product) => (
          <Select.Option value={product.id} key={product.id}>
            {product.name}
          </Select.Option>
        ))}
      </Select>
    );
    return (
      <Form>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label="Product">
              {getFieldDecorator('product_id', {
                rules: [
                  {
                    required: true,
                  },
                ],
                initialValue: personaProduct && personaProduct.product_id,
              })(productItem)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Timezone">
              {getFieldDecorator('timezone', {
                rules: [{ required: true }],
                initialValue: personaProduct ? personaProduct.timezone : null,
              })(
                <Select style={{ width: '100%' }} placeholder="Timezone" optionFilterProp="children" showSearch>
                  <Select.Option key={null} value="">
                    &nbsp;
                  </Select.Option>
                  {moment.tz.names().map((tz) => (
                    <Select.Option key={tz} value={tz}>
                      {tz}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label="User name">
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    max: 100,
                  },
                ],
                initialValue: personaProduct && personaProduct.username,
              })(<Input placeholder="Persona's user name for this product" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Password">
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    max: 100,
                  },
                ],
                initialValue: personaProduct && personaProduct.password,
              })(<Input placeholder="Persona's password for this product" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    pattern: REGEX_EMAIL,
                    message: 'The input is not valid E-mail!',
                  },
                ],
                initialValue: personaProduct ? personaProduct.email : persona.email,
              })(<Input placeholder="Persona's email for this product" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Gmail Label">
              {getFieldDecorator('gmail_label', {
                rules: [
                  {
                    required: false,
                    pattern: REGEX_ASCII,
                    message: 'Only ASCII symbols allowed',
                  },
                ],
                initialValue: personaProduct && personaProduct.gmail_label,
              })(<Input placeholder="Gmail label" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Marketing app" title="In email automation insights will be created under this app">
              {getFieldDecorator('marketing_app_id', {
                rules: [{ required: false }],
                initialValue: personaProduct ? personaProduct.marketing_app_id : productApps.map((x) => x.id)[0],
              })(
                <Select placeholder="Marketing App" allowClear>
                  <Select.Option value={null} key={null}>
                    &nbsp;
                  </Select.Option>
                  {productApps.map((app) => (
                    <Select.Option value={app.id} key={app.id}>
                      {`#${app.id} ${app.name} [${app.platform}] (${app.package})`}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        {personaProduct && (
          <Row gutter={[12, 12]}>
            <Col span={16}>
              <Select
                placeholder="Setting type"
                onChange={(value) =>
                  this.setState({
                    selectedSettingId: value,
                  })
                }
                optionFilterProp="children"
                showSearch
              >
                {onlyInLeft(settings, personaProduct ? personaProduct.settings : [], isSameSetting).map((setting) => (
                  <Select.Option value={setting.id} key={setting}>
                    {setting.type}: {setting.value}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={6}>
              <Button
                type="primary"
                disabled={!selectedSettingId}
                onClick={() => {
                  addProductSetting({ persona_setting_id: selectedSettingId });
                  this.setState({
                    selectedSettingId: null,
                  });
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
        )}
        {personaProduct && personaProduct.settings.length > 0 && (
          <Row gutter={[12, 12]}>
            <Table
              dataSource={personaProduct.settings}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id',
                },
                {
                  title: 'Type',
                  dataIndex: 'type',
                },
                {
                  title: 'Value',
                  dataIndex: 'value',
                },
                {
                  title: 'Operations',
                  render: (value, record) => (
                    <Button icon="delete" type="danger small" onClick={() => delProductSetting(record.id)} />
                  ),
                },
              ]}
              rowKey={(record) => record.id}
              pagination={false}
            />
          </Row>
        )}
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label="Subscription">
              {getFieldDecorator('tariff_plan', {
                rules: [{ required: true }],
                initialValue: personaProduct ? personaProduct.tariff_plan : tariffPlans[0],
              })(
                <Select style={{ width: '100%' }} placeholder="Subscription" optionFilterProp="children">
                  {tariffPlans.map((plan) => (
                    <Select.Option key={plan} value={plan}>
                      {plan}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Authentication method">
              {getFieldDecorator('auth_type', {
                rules: [{ required: true }],
                initialValue: personaProduct ? personaProduct.auth_type : authTypes[0],
              })(
                <Select style={{ width: '100%' }} placeholder="Auth type" optionFilterProp="children">
                  {authTypes.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item name={['payment_detail', 'payment_method']} label="Payment method">
              {getFieldDecorator('payment_method', {
                rules: [{}],
                initialValue:
                  personaProduct && personaProduct.payment_detail
                    ? personaProduct.payment_detail.payment_method
                    : paymentMethods[0],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="Payment method"
                  optionFilterProp="children"
                  onChange={(e) =>
                    this.setState({
                      payment_detail: {
                        ...this.state.payment_detail,
                        payment_method: e.toLowerCase().replace(' ', '_'),
                      },
                    })
                  }
                >
                  {paymentMethods.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          {this.state.payment_detail && this.state.payment_detail.payment_method === 'paypal' ? (
            <Col span={12}>
              <Form.Item name={['payment_detail', 'paypal_email']} label="Paypal Email">
                {getFieldDecorator('paypal_email', {
                  rules: [
                    {
                      required: true,
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                  ],
                  initialValue: this.state.payment_detail ? this.state.payment_detail.paypal_email : this.props.email,
                })(<Input placeholder="Persona's email for this product paypal account" />)}
              </Form.Item>
            </Col>
          ) : null}
          {this.state.payment_detail && this.state.payment_detail.payment_method === 'credit_card' ? (
            <Col span={12}>
              <Form.Item name={['payment_detail', 'expiration_month']} label="Expiration Month">
                {getFieldDecorator('expiration_month', {
                  rules: [
                    {
                      required: true,
                      message: 'Expiration month is required!',
                    },
                  ],
                  initialValue: this.state.payment_detail
                    ? moment(this.state.payment_detail.expiration_month, 'MM-YY')
                    : null,
                })(<DatePicker mode="day" />)}
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item label="Active Since">
              {getFieldDecorator('active_since', {
                rules: [{ required: false }],
                initialValue:
                  personaProduct && personaProduct.active_since ? moment(personaProduct.active_since) : null,
              })(<DatePicker placeholder="Active Since" style={{ width: '100%' }} format="YYYY-MM-DD" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Location">
              {getFieldDecorator('location', {
                rules: [{ required: false }],
                initialValue: personaProduct?.location || '',
              })(<Input placeholder="Location" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Browsing Cadence">
              {getFieldDecorator('browsing_cadence', {
                rules: [{ required: false }],
                initialValue: personaProduct?.browsing_cadence || '',
              })(<Input placeholder="Browsing Cadence" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Purchase Behavior">
              {getFieldDecorator('purchase_behaviour', {
                rules: [{ required: false }],
                initialValue: personaProduct?.purchase_behaviour || '',
              })(<Input placeholder="Purchase Behavior" />)}
            </Form.Item>
          </Col>
        </Row>
        {this.state.payment_detail && this.state.payment_detail.payment_method === 'credit_card' && (
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item name={['payment_detail', 'credit_card_owner']} label="Credit card owner">
                {getFieldDecorator('credit_card_owner', {
                  rules: [
                    {
                      required: true,
                      max: 100,
                    },
                  ],
                  initialValue: this.state.payment_detail ? this.state.payment_detail.credit_card_owner : '',
                })(<Input placeholder="Credit card owner" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['payment_detail', 'credit_card_number']} label="Credit card number">
                {getFieldDecorator('credit_card_number', {
                  rules: [
                    {
                      required: true,
                      max: 4,
                    },
                  ],
                  initialValue: this.state.payment_detail ? this.state.payment_detail.credit_card_number : '',
                })(<Input placeholder="Credit card number" />)}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('is_blocked', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: personaProduct ? personaProduct.is_blocked : false,
              })(<Checkbox>Is blocked user</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('auto_insight_creation', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: personaProduct
                  ? personaProduct.auto_insight_creation
                  : ['marketing', 'both'].includes(persona.scope),
              })(<Checkbox>Create insights for emails</Checkbox>)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                rules: [{ max: 1000 }],
                initialValue: personaProduct ? personaProduct.description : '',
              })(<Input.TextArea placeholder="Description" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(PersonaProductForm);

export default ({
  personaProduct,
  products,
  handleHide,
  addSubmit,
  updSubmit,
  settings,
  persona,
  addProductSetting,
  delProductSetting,
  updPaymentDetail,
  delPaymentMethod,
  apps,
}) => {
  let formRef = null;
  return (
    <Modal
      title={personaProduct ? `Change persona's product #${personaProduct.id}` : `New persona's product`}
      width="60%"
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }

            if (values && values.active_since) {
              values.active_since = moment(values.active_since).format('YYYY-MM-DD');
            }

            if (personaProduct) {
              updSubmit(personaProduct.product_id, values);
            } else {
              addSubmit(values);
            }

            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        personaProduct={personaProduct}
        products={products}
        persona={persona}
        settings={settings}
        apps={apps}
        addProductSetting={(data) => addProductSetting(personaProduct.product_id, data)}
        delProductSetting={(settingID) => delProductSetting(personaProduct.product_id, settingID)}
      />
    </Modal>
  );
};
