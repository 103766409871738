"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceModal = void 0;
var antd_1 = require("antd");
var react_1 = __importStar(require("react"));
require("./DeviceModal.scss");
var Modal = antd_1.Modal;
var DEFAULT_FIELDS = {
    deviceName: '',
};
var DeviceModal = function (_a) {
    var mode = _a.mode, onSubmit = _a.onSubmit, initialFields = _a.initialFields, usedDeviceNames = _a.usedDeviceNames, rest = __rest(_a, ["mode", "onSubmit", "initialFields", "usedDeviceNames"]);
    var _b = (0, react_1.useState)(DEFAULT_FIELDS), fields = _b[0], setFields = _b[1];
    var _c = (0, react_1.useState)(false), wasSubmitPressed = _c[0], setWasSubmitPressed = _c[1];
    (0, react_1.useEffect)(function () {
        setFields(initialFields || DEFAULT_FIELDS);
    }, [initialFields]);
    var onFieldChange = function (field) { return function (e) {
        var _a;
        setFields(__assign(__assign({}, fields), (_a = {}, _a[field] = e.currentTarget.value, _a)));
    }; };
    var onFormSubmit = function () {
        setWasSubmitPressed(true);
        if (fields.deviceName !== '' && !usedDeviceNames.has(fields.deviceName)) {
            onSubmit(fields);
        }
    };
    return (react_1.default.createElement(Modal, __assign({ onOk: onFormSubmit }, rest),
        react_1.default.createElement("h4", null, mode === 'add' ? 'Add Device' : 'Edit Device'),
        react_1.default.createElement("div", { className: "dm-form-container" },
            react_1.default.createElement(antd_1.Input, { placeholder: "Device Name", value: fields.deviceName, onChange: onFieldChange('deviceName') }),
            (fields.deviceName === '' || usedDeviceNames.has(fields.deviceName)) && wasSubmitPressed && (react_1.default.createElement("div", { className: "dm-form-error-message-block" }, "Unique device name is required")))));
};
exports.DeviceModal = DeviceModal;
