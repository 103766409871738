import React, { useEffect, useState } from 'react';
import { Select, Table, Pagination, Button, Input, Alert } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { getInsights } from '../../../redux/actions/InsightsListPage';

import { getAllUserGroups } from '../../../redux/actions/userGroupsPage';
import { PRIORITY_TYPS } from '../../../constants/priorityTyps';
import { BulkOperationModal } from './bulkOperationModal';
import _ from 'lodash';
import { getAppsData } from '../../../redux/actions/appsReleasesPage';

const InsightsListPage = ({
  isLoading,
  insights,
  getInsights,
  meta,
  error,
  getAllUserGroups,
  allUserGroups,
  history,
  getAppsData,
  apps,
}) => {
  const [currentPage, currentPageChange] = useState(1);
  const [orgID, orgIDChange] = useState('');
  const [insID, insIDChange] = useState('');
  const [priority, priorityChange] = useState([]);
  const [textSearch, textSearchChange] = useState('');
  const [isBulkOperationModalVisible, setIsBulkOperationModalVisible] = useState(false);
  const [selectedInsights, setSelectedInsights] = useState([]);
  const [appId, setAppId] = useState(null);
  const [appSearchQuery, setAppSearchQuery] = useState('');

  const selectedRowKeys = selectedInsights.map((x) => x.id);
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    getAllUserGroups();
    getAppsData();
    requestInsights();
    history.replace(`/insights/list`);
  }, []);

  useEffect(() => {
    requestInsights();
  }, [currentPage]);

  const requestInsights = (override = {}) => {
    // url: `admin/insights?id=&search=text&org_id=1&priority=high,very_high&page_num=1&num_items=100`,
    const data = {
      page_num: currentPage,
      num_items: 100,
    };
    if (orgID) {
      data.org_id = orgID;
    }
    if (appId) {
      data.app_id = appId;
    }
    if (insID) {
      data.ids = insID;
    }
    if (priority.length) {
      data.priority = priority.join(',');
    }
    if (textSearch) {
      data.search = textSearch;
    }
    Object.keys(override).forEach((key) => {
      if (override[key] && override[key].length) {
        data[key] = override[key];
      } else {
        delete data[key];
      }
    });
    getInsights(data);
  };

  const filteredApps = appSearchQuery.length
    ? apps
        .filter(
          (x) =>
            x.package_name.includes(appSearchQuery.toLowerCase()) ||
            (x.display_name || '').toLowerCase().includes(appSearchQuery.toLowerCase()) ||
            x.id === parseInt(appSearchQuery)
        )
        .slice(0, 100)
    : [];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '4rem',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: '20rem',
      render: (title, insight) => (
        <div>
          <a
            href={`/#/${insight.app.platform === 'Marketing' ? 'marketing' : 'intelligence'}/${insight.app.id}/${
              insight.id
            }`}
            target="_blank"
            style={{ textDecoration: insight.is_deleted ? 'line-through' : 'none' }}
          >
            {title}{' '}
          </a>
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '10rem',
    },
    {
      title: 'Subtype',
      dataIndex: 'content',
      key: 'syb_type',
      width: '5rem',
      render: (content, _) => content && content.sub_type,
    },
    {
      title: 'Created',
      dataIndex: 'created',
      width: '10rem',
      render: (created, insight) => <div>{moment(created).format('ll')}</div>,
    },
    {
      title: 'Visible',
      dataIndex: 'should_show',
      width: '4rem',
      render: (should_show, insight) => <div>{should_show ? '🗹' : '☐'}</div>,
    },
    {
      title: 'Deleted',
      dataIndex: 'is_deleted',
      width: '4rem',
      render: (is_deleted, insight) => <div>{is_deleted ? '🗹' : '☐'}</div>,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      width: '5rem',
    },
    {
      title: 'App',
      dataIndex: 'app',
      width: '10rem',
      render: (app, insight) => (
        <div className="app-data">
          <img src={app.icon} />
          {app.name}
        </div>
      ),
    },
    {
      title: 'Release',
      dataIndex: 'release',
      width: '10rem',
      render: (release, insight) => <div>{release ? release.name : '-'}</div>,
    },
    {
      title: 'Analyst',
      dataIndex: 'analyst',
      width: '10rem',
      render: (analyst, insight) => <div>{analyst ? analyst.name : 'Auto'}</div>,
    },
  ];

  const handleSearch = () => {
    requestInsights();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      requestInsights();
    }
  };
  const handleChangeOrg = (val) => {
    orgIDChange(val);
    requestInsights({ org_id: String(val) });
  };
  const handleChangeApp = (val) => {
    setAppId(val || null);
    requestInsights({ app_id: val && String(val) });
  };
  const handleChangePriority = (val) => {
    priorityChange(val);
    requestInsights({ priority: val });
  };

  if (!meta) {
    return 'Loading...';
  }

  return (
    <div className="insights-list">
      <div className="insights-list-filters">
        <div className="insights-list-filters-org">
          <h4>Organization</h4>
          <Select value={orgID} onChange={handleChangeOrg}>
            <Select.Option key="all" value="">
              All
            </Select.Option>
            {_.sortBy(allUserGroups, ['name']).map((org) => (
              <Select.Option key={org.id} value={org.id}>
                {org.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="insights-list-filters-app">
          <h4>App</h4>
          <Select
            showSearch
            allowClear
            filterOption={false}
            value={appId || undefined}
            onSearch={(value) => setAppSearchQuery(value)}
            onChange={(value) => handleChangeApp(value)}
            placeholder="All"
          >
            {filteredApps.map((app) => (
              <Select.Option value={app.id} key={app.id}>
                {`#${app.id} ${app.display_name} [${app.platform}] (${app.package_name})`}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="insights-list-filters-ids">
          <h4>IDs</h4>
          <Input.TextArea
            value={insID}
            onChange={(e) => insIDChange(e.target.value.replaceAll('\n', ''))}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="insights-list-filters-priority">
          <h4>Priority</h4>
          <Select mode="multiple" value={priority} onChange={handleChangePriority}>
            {Object.entries(PRIORITY_TYPS).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="insights-list-filters-text">
          <h4>Title or Description contents...</h4>
          <Input value={textSearch} onChange={(e) => textSearchChange(e.target.value)} onKeyPress={handleKeyPress} />
        </div>
        <div className="insights-list-filters-find">
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>
      </div>
      {error && <Alert message="API error" description={JSON.stringify(error)} type="error" showIcon />}
      {isBulkOperationModalVisible && (
        <BulkOperationModal
          selectedInsights={selectedInsights}
          onHide={() => setIsBulkOperationModalVisible(false)}
          refreshMainTable={requestInsights}
          setSelectedInsights={setSelectedInsights}
        />
      )}
      <div>
        <span style={{ margin: 8 }}>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>
        <Button
          type="primary"
          onClick={() => setIsBulkOperationModalVisible(true)}
          disabled={!hasSelected}
          loading={isLoading}
        >
          Bulk operation
        </Button>
      </div>
      <Table
        loading={isLoading}
        dataSource={insights}
        columns={columns}
        rowKey={(record) => record.id}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) =>
            setSelectedInsights([
              ...selectedInsights.filter((x) => newSelectedRowKeys.includes(x.id)),
              ...newSelectedRows.filter((x) => !selectedRowKeys.includes(x.id)),
            ]),
          selections: [
            {
              key: 'unselect-all',
              text: 'Unselect all',
              onSelect: () => setSelectedInsights([]),
            },
          ],
        }}
        scroll={{
          y: 600,
        }}
        pagination={false}
      />
      <Pagination
        total={meta.total}
        pageSize={100}
        current={meta.page_num}
        showTotal={(total) => `Total ${total} items`}
        onChange={(page) => currentPageChange(page)}
      />
    </div>
  );
};

const mapStateToProps = ({ insightsList, userGroupsPage, abTestsPage }) => ({
  isLoading: insightsList.isLoading,
  insights: insightsList.insights,
  meta: insightsList.meta,
  error: insightsList.error,
  allUserGroups: userGroupsPage.allUserGroups,
  apps: abTestsPage.apps,
});

const mapDispatchToProps = {
  getInsights,
  getAllUserGroups,
  getAppsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsListPage);
