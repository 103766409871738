import React, { useState } from 'react';
import moment from 'moment';
import { Icon, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { ABBR_MAP_TYPE } from '../../constants/insightType';

const { Search } = Input;

const InsightList = ({ insights, curInsight, curInsightChange, selectedInsights }) => {
  const [searchWord, searchWordChange] = useState('');
  const [insightLists, insightListsChange] = useState([
    { title: 'DRAFT', filter: (ins) => !ins.should_show, className: 'insight-lists-draft', expanded: true },
    { title: 'PUBLISHED', filter: (ins) => ins.should_show, className: 'insight-lists-published', expanded: true },
  ]);

  const handleClickIns = (ins) => {
    curInsightChange(ins);
  };

  const filterInsights = (ins) =>
    searchWord === '' || (ins.title && ins.title.toLowerCase().includes(searchWord)) || searchWord === String(ins.id);

  const handleChange = (e) => {
    if (e.target.value && e.target.value.length > 2) {
      searchWordChange(e.target.value.toLowerCase());
    } else {
      searchWordChange('');
    }
  };

  return (
    <div className="insight-lists">
      <Search
        placeholder="Type to filter"
        onSearch={(value) => searchWordChange(value.toLowerCase())}
        enterButton
        onChange={handleChange}
        className="insights-search"
      />

      {insightLists.map((setList) => (
        <div className={`${setList.className} ${setList.expanded ? 'expanded' : 'collapsed'}`}>
          <div style={{ display: 'flex', verticalAlign: 'middle' }}>
            <Icon
              className="arrow-icon"
              type={setList.expanded ? 'up' : 'down'}
              onClick={() =>
                insightListsChange(
                  insightLists.map((x) => (x.title === setList.title ? { ...x, expanded: !setList.expanded } : x))
                )
              }
            />
            <h2> {setList.title}</h2>
          </div>
          {setList.expanded && (
            <div className="insight-lists-content">
              {insights
                .filter(setList.filter)
                .filter(filterInsights)
                .map((ins) => (
                  <div
                    className={`ins-item ${curInsight && ins.id === curInsight.id ? 'active' : ''} ${
                      ins.isNew ? 'new' : ''
                    } ${selectedInsights.map((x) => x.id).includes(ins.id) ? 'marked' : ''}`}
                    onClick={() => handleClickIns(ins)}
                  >
                    <div className="ins-item-1-row">
                      {ins.type ? `${ABBR_MAP_TYPE[ins.type]} - ` : ''}
                      <Highlighter
                        highlightClassName="ins-title-highlight"
                        searchWords={[searchWord]}
                        autoEscape
                        textToHighlight={ins.title || 'No Title'}
                      />
                      <span className="ins-type"> </span>
                    </div>
                    <div className="ins-item-2-row">
                      {`${ins.id} | ${ins.created_at ? moment(ins.created_at).format('DD/MM/YYYY HH:mm:ss') : '-'}${
                        ins.created_by ? ` | ${ins.created_by.name}` : ''
                      }`}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default InsightList;
