import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Tabs, message, Popover, Radio } from 'antd';
import { isEmpty } from 'lodash';
import Marketing_ico from '../../../assets/icons/Marketing-non-selected.svg';
import Intelligence_ico from '../../../assets/icons/Intelligence-non-selected.svg';
import Benchmark_ico from '../../../assets/icons/Benchmark-non-selected.svg';

import { SubscriptionEmpty } from '../subscriptions/SubscriptionEmpty';

import Marketing_ico_hover from '../../../assets/icons/Marketing-mouse-hover.svg';
import Intelligence_ico_hover from '../../../assets/icons/Intelligence-mouse-hover.svg';
import Benchmark_ico_hovered from '../../../assets/icons/Benchmark-mouse-hover.svg';
import watchful_logo_stroke from '../../../assets/icons/watchful-logo-stroke.svg';
import subscription_ico from '../../../assets/icons/Subscriptions-mouse-hover.svg';

import chevron_white from '../../../assets/icons/chevron_white.svg';
import arrow_tab_ico from '../../../assets/icons/arrow-tab.svg';
import './EmailPreferences.scss';

const { TabPane } = Tabs;

export const EMAIL_PREF_TREE = {
  watchful_platform: ['newsletters', 'reports'],
  product_intelligence: [
    'new_meaningful_discoveries',
    'new_releases',
    'related_discoveries',
    'change_in_ab_test',
    'bi_weekly_highlights',
  ],
  product_benchmark: ['app_optimization_and_improvements'],
  marketing_campaigns: ['mass_marketing_competitors'],
};

export const EMAIL_PREF_LABELS = {
  newsletters: 'Newsletters',
  reports: 'Reports',
  new_meaningful_discoveries: 'New Meaningful Discoveries Emails',
  new_releases: 'New Discoveries Emails',
  related_discoveries: 'Related Discoveries Emails',
  change_in_ab_test: "Experiments' Status Changes",
  bi_weekly_highlights: 'Bi-weekly Highlights',
  app_optimization_and_improvements: 'Apps Optimization & Improvements Emails',
  mass_marketing_competitors: 'Mass Marketing Competitors Emails',
};

export const EMAIL_PREF_DESCR = {
  newsletters: 'Newsletters with a set of insights directly related to your product',
  reports: 'Reports with accurate information collected especially for you',
  new_meaningful_discoveries: 'Informative emails about new meaningful discoveries',
  new_releases: 'Informative emails about new discoveries',
  related_discoveries: 'Informative emails about related discoveries',
  change_in_ab_test: "Informative emails about experiments' outcomes",
  bi_weekly_highlights: 'Reports with the main discoveries collected in the last two weeks',
  app_optimization_and_improvements: 'Informative emails about new apps optimization and improvements',
  mass_marketing_competitors: 'Emails identifying mass marketing email campaigns from your monitored competitors.',
};

export const EMAIL_PREF_DEFAULT = {
  app_optimization_and_improvements: 'daily',
  mass_marketing_competitors: 'monthly',
  new_releases: 'on_demand',
  change_in_ab_test: 'on_demand',
  bi_weekly_highlights: 'bi_weekly',
  new_meaningful_discoveries: 'on_demand',
  newsletters: 'on_demand',
  related_discoveries: 'on_demand',
  reports: 'on_demand',
};

const EmailPreferences = ({ userData, editSubscription, subscriptions, updEmailPreferences }) => {
  const isIntelligence = !isEmpty(userData) ? userData.permissions.includes('pages.intelligence') : false;
  const isPerformance = !isEmpty(userData) ? userData.permissions.includes('pages.benchmark_overview') : false;
  const isMarketing = !isEmpty(userData) ? userData.permissions.includes('pages.marketing') : false;
  const [emailPref, emailPrefChange] = useState(userData ? userData.email_preferences : false);

  useEffect(() => {
    emailPrefChange(userData.email_preferences);
    message.destroy();
  }, [userData]);

  useEffect(() => {
    message.destroy();
  }, [subscriptions]);

  const onChangeParent = (e, product_par_type) => {
    const emailPrefCopy = { ...emailPref };
    if (e.target.checked) {
      EMAIL_PREF_TREE[product_par_type].forEach((type) => {
        emailPrefCopy[type] = EMAIL_PREF_DEFAULT[type];
      });
    } else {
      EMAIL_PREF_TREE[product_par_type].forEach((type) => {
        emailPrefCopy[type] = 'never';
      });
    }
    updEmailPreferences(emailPrefCopy);
    message.loading('saving...', 0);
  };

  const onChangeEmailPref = (e, email_pref) => {
    const emailPrefCopy = { ...emailPref };
    emailPrefCopy[email_pref] = e.target.checked ? EMAIL_PREF_DEFAULT[email_pref] : 'never';
    updEmailPreferences(emailPrefCopy);
    message.loading('saving...', 0);
  };
  const editSubscriptionHandle = (key, value, subscription) => {
    if (subscription === 'all') {
      subscriptions.forEach((sub) => {
        const subCopy = { ...sub, product_ids: sub.products.map((p) => p.id), label_ids: sub.labels.map((l) => l.id) };
        subCopy[key] = value;
        editSubscription(sub.id, subCopy);
      });
    } else {
      const data = {
        ...subscription,
        label_ids: subscription.labels.map((l) => l.id),
        product_ids: subscription.products.map((p) => p.id),
        [key]: value,
      };
      editSubscription(subscription.id, data);
    }
    message.loading('saving...', 0);
  };

  const renderEmailPrefs = (product_par_type) =>
    EMAIL_PREF_TREE[product_par_type].map((type, i) => (
      <div className="email-pref" key={i}>
        <Checkbox
          checked={emailPref && emailPref[type] !== 'never'}
          className="checkbox"
          onChange={(e) => onChangeEmailPref(e, type)}
        />

        <span className="email-pref-title">{EMAIL_PREF_LABELS[type]}</span>

        <div className="email-pref-descr">{EMAIL_PREF_DESCR[type]}</div>
      </div>
    ));

  return (
    <div className="subscriptions-main">
      <h3 className="title">Preferred Discovery Categories</h3>
      <div className="extra-text">Select the modules you would like the user to be notified about</div>
      <div className="subscription-buttons">
        <Tabs tabPosition="left">
          <TabPane
            tab={
              <div className="subscription-button subscription-button-enabled">
                <Checkbox
                  checked={EMAIL_PREF_TREE.watchful_platform.some((type) => emailPref && emailPref[type] !== 'never')}
                  className="checkbox"
                  onChange={(e) => onChangeParent(e, 'watchful_platform')}
                  data-testid="watchful_platform"
                />
                <img src={watchful_logo_stroke} className="icon w_platform" alt="watchful_logo_stroke" />
                Watchful Platform
                <div className="arrow-wrap">
                  <img src={arrow_tab_ico} alt="arrow" />
                </div>
              </div>
            }
            key="watchful_platform"
          >
            {renderEmailPrefs('watchful_platform')}
          </TabPane>

          <TabPane
            tab={
              <div className="subscription-button">
                <Checkbox
                  checked={
                    EMAIL_PREF_TREE.product_intelligence.some((type) => emailPref && emailPref[type] !== 'never') &&
                    isIntelligence
                  }
                  className={`checkbox ${!isIntelligence ? 'checkbox-unchecked' : ''}`}
                  onChange={(e) => onChangeParent(e, 'product_intelligence')}
                  data-testid="subscribe-intelligence"
                  disabled={!isIntelligence}
                />
                <img
                  src={isIntelligence ? Intelligence_ico_hover : Intelligence_ico}
                  className="icon"
                  alt="Intelligence_ico_hover"
                />
                Product Intelligence
                <div className="arrow-wrap">
                  <img src={arrow_tab_ico} alt="arrow_tab_ico" />
                </div>
              </div>
            }
            key="product_intelligence"
            disabled={!isIntelligence}
          >
            {renderEmailPrefs('product_intelligence')}
          </TabPane>

          <TabPane
            tab={
              <div className={`subscription-button `}>
                <Checkbox
                  checked={
                    EMAIL_PREF_TREE.product_benchmark.some((type) => emailPref && emailPref[type] !== 'never') &&
                    isPerformance
                  }
                  className={`checkbox ${!isPerformance ? 'checkbox-unchecked' : ''}`}
                  onChange={(e) => onChangeParent(e, 'product_benchmark')}
                  data-testid="product_benchmark"
                  disabled={!isPerformance}
                />
                <img
                  src={isPerformance ? Benchmark_ico_hovered : Benchmark_ico}
                  className="icon"
                  alt="Benchmark_ico_hovered"
                />
                Product Benchmark
                <div className="arrow-wrap">
                  <img src={arrow_tab_ico} alt="arrow_tab_ico" />
                </div>
              </div>
            }
            key="product_benchmark"
            disabled={!isPerformance}
          >
            {renderEmailPrefs('product_benchmark')}
          </TabPane>

          <TabPane
            tab={
              <div className="subscription-button">
                <Checkbox
                  checked={
                    EMAIL_PREF_TREE.marketing_campaigns.some((type) => emailPref && emailPref[type] !== 'never') &&
                    isMarketing
                  }
                  className={`checkbox ${!isMarketing ? 'checkbox-unchecked' : ''}`}
                  onChange={(e) => onChangeParent(e, 'marketing_campaigns')}
                  data-testid="marketing_campaigns"
                  disabled={!isMarketing}
                />
                <img
                  src={isMarketing ? Marketing_ico_hover : Marketing_ico}
                  className="icon"
                  alt="Marketing_ico_hover"
                />
                Marketing Campaigns
                <div className="arrow-wrap">
                  <img src={arrow_tab_ico} alt="arrow_tab_ico" />
                </div>
              </div>
            }
            key="marketing_campaigns"
            disabled={!isMarketing}
          >
            {renderEmailPrefs('marketing_campaigns')}
          </TabPane>

          <TabPane
            tab={
              <div className="subscription-button">
                <Checkbox
                  checked={subscriptions && subscriptions.some((sub) => sub.email) > 0}
                  className="checkbox"
                  onChange={(e) => editSubscriptionHandle('email', e.target.checked, 'all')}
                  data-testid="subscribe-subcriptions"
                />
                <img src={subscription_ico} className="icon" alt="subscription_ico" />
                My Subscriptions
                <div className="arrow-wrap">
                  <img src={arrow_tab_ico} alt="arrow_tab_ico" />
                </div>
              </div>
            }
            key="subscription"
          >
            {subscriptions && subscriptions.length > 0 ? (
              <div className="subscriptions">
                <div className="sub-digest-freq-descr">
                  DIGEST FREQUENCY TO ALL SUBSCRIPTIONS:
                  <Popover
                    content={
                      <div className="sub-freq-popup">
                        <Radio.Group
                          onChange={(e) => editSubscriptionHandle('frequency', e.target.value, 'all')}
                          value={subscriptions[0].frequency}
                        >
                          <Radio value="daily">Daily</Radio>
                          <Radio value="weekly">Weekly</Radio>
                        </Radio.Group>
                      </div>
                    }
                    placement="bottom"
                  >
                    <Button>
                      {subscriptions[0].frequency} <img src={chevron_white} alt="chevron_white" />
                    </Button>
                  </Popover>
                </div>
                {subscriptions.map((sub) => (
                  <div className="email-pref">
                    <Checkbox
                      checked={sub.email}
                      className="checkbox"
                      onChange={(e) => editSubscriptionHandle('email', e.target.checked, sub)}
                    />

                    <span className="email-pref-title sub-title">{sub.name}</span>

                    <div className="email-pref-sub-labels">
                      {sub.labels.map((lab) => (
                        <div className="tags__tags-tag-element" key={lab.id}>
                          {lab.name}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <SubscriptionEmpty />
            )}
          </TabPane>
        </Tabs>
      </div>
      <div className="email-pref-management">
        <Button
          className="unsubscribe-button"
          data-testid="unsubscribe-from-all-emails"
          onClick={() => {
            const clearedEmailPref = Object.values(EMAIL_PREF_TREE)
              .flat()
              .reduce((acc, type) => ({ ...acc, [type]: 'never' }), {});
            updEmailPreferences(clearedEmailPref);
            editSubscriptionHandle('email', false, 'all');
          }}
        >
          <span className="unsubscribe-text">Unsubscribe from all emails</span>
        </Button>
      </div>
    </div>
  );
};

export default EmailPreferences;
