"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSuggestions = void 0;
var antd_1 = require("antd");
var auto_classification_api_1 = require("api/auto-classification-api");
var react_query_1 = require("react-query");
var useSuggestions = function (_a) {
    var _b, _c, _d;
    var insight_id = _a.insight_id, type = _a.type;
    var queryClient = (0, react_query_1.useQueryClient)();
    var _e = (0, react_query_1.useQuery)(['auto-classifications', insight_id], function () { return (0, auto_classification_api_1.getAutoClassificationsByInsightId)({ insight_id: insight_id }); }, {
        enabled: !!insight_id,
    }), data = _e.data, isFetching = _e.isFetching;
    var filteredData = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.filter(function (suggestion) { return suggestion.attr_name === type; })) === null || _c === void 0 ? void 0 : _c.filter(function (suggestion) { return suggestion.human_eval_score === null; })) === null || _d === void 0 ? void 0 : _d.map(function (suggestion) { return ({
        id: suggestion.id,
        name: suggestion.attr_value,
    }); });
    var applyAutoClassificationMutation = (0, react_query_1.useMutation)(auto_classification_api_1.applyAutoClassification);
    var onApplySuggestion = function (_a) {
        var suggestion_id = _a.suggestion_id;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, applyAutoClassificationMutation.mutateAsync({
                            autoclassification_id: suggestion_id.toString(),
                            data: {
                                applied_at: new Date().toISOString(),
                                human_eval_score: 1,
                            },
                        })];
                    case 1:
                        _b.sent();
                        queryClient.invalidateQueries(['auto-classifications', insight_id]);
                        antd_1.message.info('Suggestion applied');
                        return [2 /*return*/];
                }
            });
        });
    };
    var onRejectSuggestion = function (_a) {
        var suggestion_id = _a.suggestion_id;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, applyAutoClassificationMutation.mutateAsync({
                            autoclassification_id: suggestion_id.toString(),
                            data: {
                                applied_at: new Date().toISOString(),
                                human_eval_score: -1,
                            },
                        })];
                    case 1:
                        _b.sent();
                        queryClient.invalidateQueries(['auto-classifications', insight_id]);
                        antd_1.message.info('Suggestion rejected');
                        return [2 /*return*/];
                }
            });
        });
    };
    return {
        suggestions: filteredData || [],
        suggestionsLoading: isFetching,
        onApplySuggestion: onApplySuggestion,
        onRejectSuggestion: onRejectSuggestion,
    };
};
exports.useSuggestions = useSuggestions;
