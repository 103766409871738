import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addDeliveryMerchant,
  delDeliveryMerchant,
  updDeliveryMerchant,
} from "../../api/deliveryAPI";
import { Alert, Button, Divider, Icon, Popconfirm, Table } from 'antd';
import MerchantModal from "./MerchantModal";

export default ({ merchantsQuery }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentEditMerchantId, setCurrentEditMerchantId] = useState(null)
  const queryClient = useQueryClient();
  const addMerchantMutation = useMutation(addDeliveryMerchant, {
    onSuccess: () => queryClient.invalidateQueries(['merchants']),
  });
  const updMerchantMutation = useMutation(updDeliveryMerchant, {
    onSuccess: () => queryClient.invalidateQueries(['merchants']),
  });
  const delMerchantMutation = useMutation(delDeliveryMerchant, {
    onSuccess: () => queryClient.invalidateQueries(['merchants']),
  });
  const isLoading = [
    merchantsQuery,
    addMerchantMutation,
    updMerchantMutation,
    delMerchantMutation,
  ].some(x => x.isLoading)
  const error = [
    merchantsQuery,
    addMerchantMutation,
    updMerchantMutation,
    delMerchantMutation,
  ].find((x) => x.isError)

  return (<React.Fragment>
    {error && <Alert
      message="API Error"
      description={error?.error?.message || JSON.stringify(error)}
      type="error"
      showIcon
    />}
    {isModalVisible && (
      <MerchantModal
        merchant={currentEditMerchantId && merchantsQuery.data?.data?.find((x) => x.id === currentEditMerchantId)}
        handleHide={() => {
          setIsModalVisible(false);
          setCurrentEditMerchantId(null);
        }}
        addSubmit={body => addMerchantMutation.mutateAsync(body)}
        updSubmit={(merchantId, body) => updMerchantMutation.mutateAsync({ merchantId, body })}
      />
    )}
    <Button
      type="small"
      icon="plus"
      title="Add Merchant"
      onClick={() => setIsModalVisible(true)}
      loading={isLoading}
    >Add Merchant</Button>
    <Table
      className="main-table"
      columns={[
        {
          title: '',
          dataIndex: 'id',
          width: '7rem',
          render: (id, record) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                type="small"
                icon="edit"
                title="Update Merchant"
                onClick={() => {
                  setIsModalVisible(true)
                  setCurrentEditMerchantId(id)
                }}
              />
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure?"
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={() => delMerchantMutation.mutateAsync(id)}
              >
                <Button icon="delete" type="danger small" title="Delete Merchant" />
              </Popconfirm>
            </div>
          ),
        },
        {
          title: 'ID',
          dataIndex: 'id',
        },
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
          title: 'City',
          dataIndex: 'city',
        },
        {
          title: 'Address',
          dataIndex: 'address',
        },
      ]}
      loading={isLoading}
      dataSource={merchantsQuery.data?.data}
      pagination={false}
    />
  </React.Fragment>);
}
