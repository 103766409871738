"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonaSelect = void 0;
var antd_1 = require("antd");
var react_1 = __importStar(require("react"));
var Select = antd_1.Select;
var MAX_PERSONAS_TO_SHOW = 30;
var PersonaSelect = function (_a) {
    var persona = _a.persona, personas = _a.personas, onSelect = _a.onSelect;
    var _b = (0, react_1.useState)(''), search = _b[0], setSearch = _b[1];
    var filteredPersonas = (0, react_1.useMemo)(function () {
        return personas
            .filter(function (personas) { return personas.title.toLowerCase().includes(search.toLowerCase()); })
            .slice(0, MAX_PERSONAS_TO_SHOW);
    }, [search, personas]);
    var handleSearch = function (val) {
        setSearch(val);
    };
    return (react_1.default.createElement(Select, { showSearch: true, style: { width: 250 }, placeholder: "Select a Application", optionFilterProp: "children", onChange: onSelect, onSearch: handleSearch, value: persona ? "".concat(persona.title) : null }, filteredPersonas.map(function (persona) { return (react_1.default.createElement(Select.Option, { key: persona.id, value: persona.id }, persona.title)); })));
};
exports.PersonaSelect = PersonaSelect;
