/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Form, Input, Row, Col, message } from 'antd';

class ProductForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="add-app-form">
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Product Name">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter the product name!',
                  },
                ],
                initialValue: this.props.product && this.props.product.name,
              })(<Input placeholder="Product name" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Thumbnail">
              {getFieldDecorator('thumbnail_original', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a thumbnail',
                  },
                  {
                    type: 'url',
                    message: 'Please enter a valid url',
                  },
                ],
                initialValue: this.props.product && this.props.product.thumbnail_original,
              })(<Input placeholder="Product thumbnail" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedAppCreationForm = Form.create({ name: 'product_form' })(ProductForm);

export default function ProductModal({ visible, handleHide, handleSubmit, product, products }) {
  let formRef = null;

  return (
    <Modal
      title={product ? `Change product #${product.id}` : 'Add new product'}
      centered
      visible={visible}
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (products.filter((x) => !product || x.id !== product.id).some((p) => p.name === values.name)) {
              message.error(`Product ${values.name} already exist`);
              return;
            }
            handleSubmit({ ...values, id: product ? product.id : undefined });
            if (product) {
              formRef.props.form.resetFields();
            }
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
        if (product) {
          formRef.props.form.resetFields();
        }
      }}
    >
      <WrappedAppCreationForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        product={product}
      />
    </Modal>
  );
}
