"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceTable = void 0;
var antd_1 = require("antd");
var react_1 = __importStar(require("react"));
require("./DeviceTable.scss");
var DeviceModal_1 = require("./DeviceModal");
var useDeviceData_1 = require("components/persona-management/hooks/useDeviceData");
var DeviceTable = function (_a) {
    var devices = _a.devices;
    var updatePersonasManagementDataMutation = (0, useDeviceData_1.useUpdatePersonasManagementDataMutation)();
    var isLoading = updatePersonasManagementDataMutation.isLoading;
    var usedDeviceNames = (0, react_1.useMemo)(function () {
        return new Set(devices.map(function (device) { return device.device_name; }));
    }, [devices]);
    var _b = (0, react_1.useState)({
        deviceName: '',
        visible: false,
        mode: 'add',
        usedDeviceNames: usedDeviceNames,
    }), modalProps = _b[0], setModalProps = _b[1];
    var tableData = devices.map(function (device) { return ({
        device_name: device.device_name,
        apps: device.apps,
        banner_scrapper_schedule: device.banner_scrapper_schedule,
    }); });
    var handleAddOpen = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setModalProps({ visible: true, deviceName: '', mode: 'add', usedDeviceNames: usedDeviceNames });
            return [2 /*return*/];
        });
    }); };
    var handleEditOpen = function (deviceName, initialFields) { return function () {
        var exceptCurrentDevice = new Set(usedDeviceNames);
        exceptCurrentDevice.delete(deviceName);
        setModalProps({ visible: true, mode: 'edit', initialFields: initialFields, deviceName: deviceName, usedDeviceNames: exceptCurrentDevice });
    }; };
    var onClose = function () {
        setModalProps(function (prev) { return (__assign(__assign({}, prev), { visible: false })); });
    };
    var onCreate = function (fields) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updatePersonasManagementDataMutation.mutateAsync({
                        devices: __spreadArray(__spreadArray([], devices, true), [
                            {
                                device_name: fields.deviceName,
                                apps: [],
                                banner_scrapper_schedule: {
                                    enabled: false,
                                    hours: 0,
                                    minutes: 0,
                                },
                            },
                        ], false),
                    })];
                case 1:
                    _a.sent();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var onUpdate = function (fields) { return __awaiter(void 0, void 0, void 0, function () {
        var deviceName, updatedDevices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    deviceName = modalProps.deviceName;
                    if (!deviceName)
                        return [2 /*return*/];
                    updatedDevices = devices.map(function (device) {
                        if (device.device_name === deviceName) {
                            return __assign(__assign({}, device), { device_name: fields.deviceName });
                        }
                        return device;
                    });
                    return [4 /*yield*/, updatePersonasManagementDataMutation.mutateAsync({
                            devices: updatedDevices,
                        })];
                case 1:
                    _a.sent();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var onDeleteDevice = function (deviceName) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedDevices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updatedDevices = devices.filter(function (device) { return device.device_name !== deviceName; });
                    return [4 /*yield*/, updatePersonasManagementDataMutation.mutateAsync({
                            devices: updatedDevices,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }; };
    var onAddApp = function (deviceName) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedDevices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updatedDevices = devices.map(function (device) {
                        if (device.device_name === deviceName) {
                            return __assign(__assign({}, device), { apps: __spreadArray(__spreadArray([], device.apps, true), [
                                    {
                                        package_name: '',
                                        persona_email: '',
                                        app_id: 0, // todo - remove
                                    },
                                ], false) });
                        }
                        return device;
                    });
                    return [4 /*yield*/, updatePersonasManagementDataMutation.mutateAsync({
                            devices: updatedDevices,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }; };
    var onDeleteApp = function (deviceName, rowIdx) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedDevices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updatedDevices = devices.map(function (device) {
                        if (device.device_name === deviceName) {
                            return __assign(__assign({}, device), { apps: device.apps.filter(function (_, idx) { return idx !== rowIdx; }) });
                        }
                        return device;
                    });
                    return [4 /*yield*/, updatePersonasManagementDataMutation.mutateAsync({
                            devices: updatedDevices,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }; };
    var onChangeBannerScrapperScheduleSetting = function (deviceName, data) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedDevices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updatedDevices = devices.map(function (device) {
                        if (device.device_name === deviceName) {
                            return __assign(__assign({}, device), { banner_scrapper_schedule: __assign(__assign({}, device.banner_scrapper_schedule), data) });
                        }
                        return device;
                    });
                    return [4 /*yield*/, updatePersonasManagementDataMutation.mutateAsync({
                            devices: updatedDevices,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChangeAppField = function (deviceName, rowIdx, field) { return function (e) {
        var value = e.currentTarget.value;
        if (!value)
            return;
        var didChange = false;
        var updatedDevices = devices.map(function (device) {
            if (device.device_name === deviceName) {
                return __assign(__assign({}, device), { apps: device.apps.map(function (app, idx) {
                        var _a;
                        if (idx === rowIdx) {
                            didChange = app[field] != value;
                            return __assign(__assign({}, app), (_a = {}, _a[field] = value, _a));
                        }
                        return app;
                    }) });
            }
            return device;
        });
        if (!didChange)
            return;
        updatePersonasManagementDataMutation.mutate({
            devices: updatedDevices,
        });
    }; };
    var modeToSubmit = {
        add: onCreate,
        edit: onUpdate,
    };
    var DeviceTableCols = [
        {
            title: 'Device Name',
            dataIndex: 'device_name',
            width: '460px',
            render: function (text, row) {
                return (react_1.default.createElement("div", { className: "device-actions" },
                    react_1.default.createElement("span", { className: "device-id" }, row.device_name),
                    react_1.default.createElement("div", { className: "device-actions-buttons" },
                        react_1.default.createElement(antd_1.Button, { disabled: isLoading, type: "default", className: "action-button", onClick: handleEditOpen(row.device_name, {
                                deviceName: row.device_name,
                            }) }, "Edit"),
                        react_1.default.createElement(antd_1.Popconfirm, { title: "Are you sure you want to delete this device?", okText: "Delete", onConfirm: onDeleteDevice(row.device_name) },
                            react_1.default.createElement(antd_1.Button, { disabled: isLoading, type: "danger", className: "action-button" }, "Delete")))));
            },
        },
        {
            title: 'Banner Scraper',
            width: '200px',
            render: function (_, row) {
                var _a, _b, _c, _d;
                return (react_1.default.createElement("div", { className: "banner-scrapper-schedule" },
                    react_1.default.createElement(antd_1.Checkbox, { disabled: isLoading, checked: ((_a = row === null || row === void 0 ? void 0 : row.banner_scrapper_schedule) === null || _a === void 0 ? void 0 : _a.enabled) || false, onChange: function (e) { return onChangeBannerScrapperScheduleSetting(row.device_name, { enabled: e.target.checked }); } }, "Enabled"),
                    ((_b = row === null || row === void 0 ? void 0 : row.banner_scrapper_schedule) === null || _b === void 0 ? void 0 : _b.enabled) && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("div", { style: { margin: '0.2rem 0' } },
                            react_1.default.createElement("label", { htmlFor: "".concat(row.device_name, "-package-name-banner-scrapper-schedule-hours"), style: { paddingRight: '0.5rem' } }, "Hours"),
                            react_1.default.createElement(antd_1.InputNumber, { disabled: isLoading, placeholder: "Hours", value: ((_c = row === null || row === void 0 ? void 0 : row.banner_scrapper_schedule) === null || _c === void 0 ? void 0 : _c.hours) || 0, min: 0, max: 23, onBlur: function (e) {
                                    return onChangeBannerScrapperScheduleSetting(row.device_name, { hours: e.currentTarget.value });
                                }, name: "".concat(row.device_name, "-package-name-banner-scrapper-schedule-hours") })),
                        react_1.default.createElement("div", null,
                            react_1.default.createElement("label", { htmlFor: "".concat(row.device_name, "-package-name-banner-scrapper-schedule-minutes"), style: { paddingRight: '0.5rem' } }, "Minutes"),
                            react_1.default.createElement(antd_1.InputNumber, { disabled: isLoading, placeholder: "Minutes", value: ((_d = row === null || row === void 0 ? void 0 : row.banner_scrapper_schedule) === null || _d === void 0 ? void 0 : _d.minutes) || 0, min: 0, max: 59, onBlur: function (e) {
                                    return onChangeBannerScrapperScheduleSetting(row.device_name, { minutes: e.currentTarget.value });
                                }, name: "".concat(row.device_name, "-package-name-banner-scrapper-schedule-minutes") }))))));
            },
        },
        {
            title: 'Package',
            width: '230px',
            render: function (_, row) {
                var apps = row.apps;
                var packageNames = apps.map(function (app) { return app.package_name; });
                return (react_1.default.createElement("div", { className: "selects-container" }, packageNames.map(function (packageName, idx) { return (react_1.default.createElement(antd_1.Input, { disabled: isLoading, placeholder: "Package Name", key: "".concat(row.device_name, "-").concat(idx, "-package-name"), defaultValue: packageName, onBlur: handleChangeAppField(row.device_name, idx, 'package_name') })); })));
            },
        },
        {
            title: 'Persona',
            width: '300px',
            render: function (_, row) {
                var apps = row.apps;
                var personaEmails = apps.map(function (app) { return app.persona_email; });
                return (react_1.default.createElement("div", { className: "selects-container" }, personaEmails.map(function (personaEmail, idx) { return (react_1.default.createElement(antd_1.Input, { disabled: isLoading, placeholder: "Persona Email", key: "".concat(row.device_name, "-").concat(idx, "-persona-email"), defaultValue: personaEmail, onBlur: handleChangeAppField(row.device_name, idx, 'persona_email') })); })));
            },
        },
        {
            title: 'Delete',
            render: function (_, row) {
                var apps = row.apps;
                return (react_1.default.createElement("div", { className: "selects-container" }, apps.map(function (_, idx) { return (react_1.default.createElement(antd_1.Popconfirm, { key: idx, title: "Are you sure you want to delete this app?", okText: "Delete", onConfirm: onDeleteApp(row.device_name, idx) },
                    react_1.default.createElement(antd_1.Button, { disabled: isLoading, icon: "delete", type: "danger", className: "action-button" }))); })));
            },
        },
        {
            title: 'Add application',
            render: function (_, row) {
                return (react_1.default.createElement("div", { className: "add-app-container" },
                    react_1.default.createElement(antd_1.Button, { disabled: isLoading, onClick: onAddApp(row.device_name), type: "primary", className: "action-button" }, "Add")));
            },
        },
    ];
    return (react_1.default.createElement("div", { className: "dt-container" },
        react_1.default.createElement(antd_1.Button, { disabled: isLoading, type: "primary", onClick: handleAddOpen }, "Add Device"),
        react_1.default.createElement(antd_1.Table, { pagination: { defaultPageSize: 9999 }, columns: DeviceTableCols, dataSource: tableData, bordered: true }),
        react_1.default.createElement(DeviceModal_1.DeviceModal, __assign({}, modalProps, { onSubmit: modeToSubmit[modalProps.mode], onCancel: onClose }))));
};
exports.DeviceTable = DeviceTable;
1;
