import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addDeliveryOrder, delDeliveryOrder,
  getDeliveryOrders, updDeliveryOrder
} from "../../api/deliveryAPI";
import { Alert, Button, Divider, Icon, Popconfirm, Table } from 'antd';
import OrderModal from "./OrderModal";
import { getApps } from "../../api/appsAPI";
import { getPersonasByApp } from "../../api/insightsAPI";
import Persona from "./Persona";

export default ({ merchantsQuery }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentEditOrderId, setCurrentEditOrderId] = useState(null)
  const queryClient = useQueryClient();
  const ordersQuery = useQuery(['orders'], getDeliveryOrders);
  const appsQuery = useQuery(['apps'], getApps);
  const personasQuery = useQuery(['personas'], () => getPersonasByApp(undefined));
  const addOrderMutation = useMutation(addDeliveryOrder, {
    onSuccess: () => queryClient.invalidateQueries(['orders']),
  });
  const updOrderMutation = useMutation(updDeliveryOrder, {
    onSuccess: () => queryClient.invalidateQueries(['orders']),
  });
  const delOrderMutation = useMutation(delDeliveryOrder, {
    onSuccess: () => queryClient.invalidateQueries(['orders']),
  });
  const isLoading = [
    merchantsQuery,
    ordersQuery,
    addOrderMutation,
    updOrderMutation,
    delOrderMutation,
  ].some(x => x.isLoading)
  const error = [
    merchantsQuery,
    ordersQuery,
    addOrderMutation,
    updOrderMutation,
    delOrderMutation,
  ].find((x) => x.isError)
  return (<React.Fragment>
    {error && <Alert
      message="API Error"
      description={error?.error?.message || JSON.stringify(error)}
      type="error"
      showIcon
    />}
    {isModalVisible && (
      <OrderModal
        order={currentEditOrderId && ordersQuery.data?.data?.find((x) => x.id === currentEditOrderId)}
        handleHide={() => {
          setIsModalVisible(false);
          setCurrentEditOrderId(null);
        }}
        addSubmit={body => addOrderMutation.mutateAsync(body)}
        updSubmit={(orderId, body) => updOrderMutation.mutateAsync({ orderId, body })}
        merchants={merchantsQuery.data?.data || []}
        apps={appsQuery.data || []}
        personas={personasQuery.data?.data || []}
      />
    )}
    <Button
      type="small"
      icon="plus"
      title="Add Order"
      onClick={() => setIsModalVisible(true)}
    >Add Order</Button>
    <Table
      className="main-table"
      columns={[
        {
          title: '',
          dataIndex: 'id',
          width: '7rem',
          render: (id, record) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                type="small"
                icon="edit"
                title="Update Order"
                onClick={() => {
                  setIsModalVisible(true)
                  setCurrentEditOrderId(id)
                }}
              />
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure?"
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={() => delOrderMutation.mutateAsync(id)}
              >
                <Button icon="delete" type="danger small" title="Delete Order" />
              </Popconfirm>
            </div>
          ),
        },
        {
          title: 'ID',
          dataIndex: 'id',
        },
        {
          title: 'Time Of Order',
          dataIndex: 'time_of_order',
        },
        {
          title: 'Persona',
          dataIndex: 'persona',
          render: (value, record) => <Persona persona={value} />,
        },
        {
          title: 'App',
          dataIndex: 'app',
          render: (value, record) => <div>{`#${value.id} ${value.name} [${value.platform}] (${value.package})`}</div>,
        },
        {
          title: 'Merchant',
          dataIndex: 'merchant',
          render: (value, record) => <div>#{value.id} {value.name}</div>,
        },
        {
          title: 'Cart Size',
          dataIndex: 'cart_size',
        },
        {
          title: 'Cart Size Curr',
          dataIndex: 'cart_size_curr',
        },
        {
          title: 'Persona Coords',
          dataIndex: 'persona_lat',
          render: (value, record) => <div>{record.persona_lat},{record.persona_long}</div>,
        },
        {
          title: 'Persona City',
          dataIndex: 'persona_city',
        },
        {
          title: 'Order Type',
          dataIndex: 'order_type',
        },
        {
          title: 'Distance(km)',
          dataIndex: 'distance_km',
        },
        {
          title: 'Store Card Fee Text',
          dataIndex: 'store_card_fee_text',
        },
        {
          title: 'Store Card ETA',
          dataIndex: 'store_card_eta',
        },
        {
          title: 'Store Header Fee Text',
          dataIndex: 'store_header_fee_text',
        },
        {
          title: 'Store Header ETA',
          dataIndex: 'store_header_eta',
        },
        {
          title: 'Store Card Fee',
          dataIndex: 'store_card_fee',
        },
        {
          title: 'Store Header Fee',
          dataIndex: 'store_header_fee',
        },
        {
          title: 'Checkout Fees Options',
          dataIndex: 'checkout_fees_options',
          render: (value, record) => <code>{JSON.stringify(value)}</code>,
        },
        {
          title: 'Checkout Etas Options',
          dataIndex: 'checkout_etas_options',
          render: (value, record) => <code>{JSON.stringify(value)}</code>,
        },
        {
          title: 'Checkout Final Fees',
          dataIndex: 'checkout_final_fees',
        },
        {
          title: 'Checkout Plan',
          dataIndex: 'checkout_plan',
        },
        {
          title: 'Preparing Order Timestamp',
          dataIndex: 'preparing_order_timestamp',
        },
        {
          title: 'Preparing Order ETA',
          dataIndex: 'preparing_order_eta',
        },
        {
          title: 'Courier Assigned Timestamp',
          dataIndex: 'courier_assigned_timestamp',
        },
        {
          title: 'Courier Assigned ETA',
          dataIndex: 'courier_assigned_eta',
        },
        {
          title: 'Courier Ontheway Timestamp',
          dataIndex: 'courier_ontheway_timestamp',
        },
        {
          title: 'Courier Ontheway ETA',
          dataIndex: 'courier_ontheway_eta',
        },
        {
          title: 'Order Arrived Timestamp',
          dataIndex: 'order_arrived_timestamp',
        },
        {
          title: 'Order Arrived',
          dataIndex: 'order_arrived',
        },
        {
          title: 'Order Arrived On Last ETA',
          dataIndex: 'order_arrived_on_last_eta',
          render: (value) => (value ? 'Yes' : 'No'),
        },
        {
          title: 'Video Link',
          dataIndex: 'video_link',
          render: (value) => (value ? <a rel="noopener noreferrer" target="_blank" href={value}>{value}</a> : ''),
        },
      ]}
      loading={isLoading}
      dataSource={ordersQuery.data?.data}
      pagination={false}
      scroll={{ x: true }}
    />
  </React.Fragment>);
}
