import React from 'react';
import { INSIGHT_TYPE, INSIGHT_CAMPAIGN_SUBTYPE, MARKETING_INSIGHT_TYPES } from '../../../constants/insightType';
import { Select, Input } from 'antd';
import { useState } from 'react';
import { InsightFieldSuggestions } from '../../insight-field-suggestions';
import { useSuggestions } from '../../insight-field-suggestions/hooks/useSuggestions';

const CATEGORIES = {
  mass_market: 'Mass Market',
  mass_market_by_segment: 'Mass Market by Segment',
  automated: 'Automated',
};

const CATEGORY_OPTIONS = Object.keys(CATEGORIES).map((key) => ({
  text: CATEGORIES[key],
  value: key,
  id: key,
  key,
}));

const FUNNEL_STAGES = {
  prospects_nursery: 'Prospects/Nursery',
  onboarding: 'Onboarding',
  retention: 'Cross-Sell, Up-Sell & Retention',
  lapsed: 'Lapsed',
  transactional: 'Transactional/Process',
};

const FUNNEL_STAGE_OPTIONS = Object.keys(FUNNEL_STAGES).map((key) => ({
  text: FUNNEL_STAGES[key],
  value: key,
  id: key,
  key,
}));

const INSIGHT_CAMPAIGN_SUBTYPE_OPTIONS = Object.keys(INSIGHT_CAMPAIGN_SUBTYPE).map((type) => ({
  text: INSIGHT_CAMPAIGN_SUBTYPE[type],
  value: type,
  id: type,
  key: type,
}));

export default function InsightMarketingType({ insight, handleInsChange }) {
  const [locEmailTitle, locEmailTitleChange] = useState(insight.content.emailTitle);

  const updateMarkType = (key, val) => {
    handleInsChange({
      content: {
        ...insight.content,
        [key]: val,
      },
    });
  };

  const { suggestions, onApplySuggestion, onRejectSuggestion } = useSuggestions({
    insight_id: insight.id,
    type: 'sub_type',
  });

  const handleApplyFocusAreaSuggestion = async (suggestion) => {
    await onApplySuggestion({ suggestion_id: suggestion.id });

    const sub_type_name = suggestion.name;

    const sub_type_id = Object.keys(INSIGHT_CAMPAIGN_SUBTYPE).find(
      (key) => INSIGHT_CAMPAIGN_SUBTYPE[key].toLowerCase() === sub_type_name.toLowerCase()
    );

    if (!sub_type_id) return;

    updateMarkType('sub_type', sub_type_id);
  };

  const handleRejectFocusAreaSuggestion = async (suggestion) => {
    await onRejectSuggestion({ suggestion_id: suggestion.id });
  };

  return (
    <div className="ins-marketing-types">
      <div className="fields">
        <div className="field width-25p">
          <label>Email Title</label>
          <Input
            value={locEmailTitle}
            onChange={(e) => locEmailTitleChange(e.target.value)}
            onBlur={(e) => updateMarkType('emailTitle', e.target.value)}
          />
        </div>

        <div className="field width-25p">
          <label>Category(sub_type)</label>
          <Select
            value={insight.content.sub_type}
            onChange={(val) => updateMarkType('sub_type', val)}
            getPopupContainer={(trNode) => trNode}
          >
            <Select.Option value={null} key={null}>
              &nbsp;
            </Select.Option>
            {INSIGHT_CAMPAIGN_SUBTYPE_OPTIONS.map((opt) => (
              <Select.Option value={opt.value} key={opt.key}>
                {opt.text}
              </Select.Option>
            ))}
          </Select>
          {suggestions && suggestions.length > 0 && (
            <InsightFieldSuggestions
              title="Suggestions (sub_type)"
              suggestions={suggestions}
              onApproveSuggestion={handleApplyFocusAreaSuggestion}
              onRejectSuggestion={handleRejectFocusAreaSuggestion}
            />
          )}
        </div>

        <div className="field width-25p">
          <label>Category</label>
          <Select
            value={insight.content.category}
            onChange={(val) => updateMarkType('category', val)}
            getPopupContainer={(trNode) => trNode}
          >
            {CATEGORY_OPTIONS.map((opt) => (
              <Select.Option value={opt.value} key={opt.key}>
                {opt.text}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="field width-25p">
          <label>Funnel Stage</label>
          <Select
            value={insight.content.funnelStage}
            onChange={(val) => updateMarkType('funnelStage', val)}
            getPopupContainer={(trNode) => trNode}
          >
            {FUNNEL_STAGE_OPTIONS.map((opt) => (
              <Select.Option value={opt.value} key={opt.key}>
                {opt.text}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
